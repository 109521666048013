import $ from 'jquery';
import Inputmask from 'inputmask';

let init = true;

const ipi = (contexto) => {
  const prefixo = (contexto !== '' ? `${contexto}-` : '');
  /**
   * Código de Situação Tributária do Ipi
   * @type {jQuery|HTMLElement|*}
   */
  const cst = $(`#${prefixo}ipi-imposto-ipi-dado-id`);

  /**
   * Tipo de Cálculo Ipi
   * @type {jQuery|HTMLElement|*}
   */
  const tipoCalculo = $(`#${prefixo}ipi-imposto-tipo-calculo`);

  /**
   * Alíquota do Ipi
   * @type {jQuery|HTMLElement|*}
   */
  const aliq = $(`#${prefixo}ipi-imposto-valor`);
  /**
   * Código de enquadramento
   * @type {jQuery|HTMLElement|*}
   */
  const cEnq = $(`#${prefixo}ipi-imposto-c-enq`);

  /**
   * Habilitar input
   * @param  {jQuery|HTMLElement|*} field
   * @returns {jQuery|HTMLElement|*}
   */
  const habilitar = (field) => {
    field
      .prop('required', true)
      .closest('.form-group')
      .addClass('required')
      .show();
    return field;
  };

  /**
   * Desabilitar input
   * @param  {jQuery|HTMLElement|*} field
   * @returns {jQuery|HTMLElement|*}
   */
  const desabilitar = (field) => {
    if (!init) {
      if (field === tipoCalculo) {
        field.val(0).trigger('change.select2');
      } else {
        field.val(null);
      }
    }
    field
      .prop('required', false)
      .closest('.form-group')
      .removeClass('required')
      .hide();
    return field;
  };

  /**
   * Altera input para porcentagem
   * @param {jQuery|HTMLElement|*} field
   * @returns {jQuery|HTMLElement|*}
   */
  const porcentagem = (field) => {
    const container = field.closest('.input-group');

    container.find('span').remove();
    container.append('<span class="input-group-append"><span class="input-group-text">%</span></span>');
    Inputmask().remove(field);
    field.attr('data-inputmask-alias', 'percentage');
    Inputmask().mask(field);

    return field;
  };

  /**
   * Altera input para monetário
   * @param {jQuery|HTMLElement|*} field
   * @returns {jQuery|HTMLElement|*}
   */
  const monetario = (field) => {
    const container = field.closest('.input-group');

    container.find('span').remove();
    container.prepend('<span class="input-group-prepend"><span class="input-group-text">R$</span></span>');
    Inputmask().remove(field);
    field.attr('data-inputmask-alias', 'currency');
    Inputmask().mask(field);

    return field;
  };

  cst.on('change.select2', () => {
    /**
     * Código Situação Tributária
     * @type {*|jQuery}
     */
    const codigo = cst.find('option:checked').data('codigo');

    desabilitar(tipoCalculo);
    desabilitar(aliq);
    desabilitar(cEnq);

    if (typeof codigo !== 'undefined') {
      switch (codigo.toString()) {
        case '00': /** Entrada com Recuperação de Crédito */
        case '49': /** Outras Entradas */
        case '50': /** Saída Tributada */
        case '99': /** Outras Saídas */
          if (!init) {
            tipoCalculo.val(0);
          }
          habilitar(tipoCalculo.trigger('change.select2'));
          break;
        case '01': /** Entrada Tributável com Alíquota Zero */
        case '02': /** Entrada Isenta */
        case '03': /** Entrada Não-Tributada */
        case '04': /** Entrada Imune */
        case '05': /** Entrada com Suspensão */
        case '51': /** Saída Tributável com Alíquota Zero */
        case '52': /** Saída Isenta */
        case '53': /** Saída Não-Tributada */
        case '54': /** Saída Imune */
        case '55': /** Saída com Suspensão */
          break;
        default:
          break;
      }
      habilitar(cEnq);
    }
    init = false;
  });

  tipoCalculo.on('change.select2', () => {
    switch (tipoCalculo.val()) {
      case '0': /** Não usar */
        desabilitar(aliq);
        break;
      case '1': /** Porcentagem */
        habilitar(porcentagem(aliq));
        break;
      case '2': /** Em valor */
        habilitar(monetario(aliq));
        break;
      default:
        break;
    }
  });

  cst.trigger('change.select2');
};

window.initIpi = ipi;
