import $ from 'jquery';
import {unmaskedValue, maskedValue} from "../inputmask";
import {round10} from 'expected-round';

const nfe = () => {
  /**
   * Natureza Operação
   * @type {jQuery|HTMLElement|*}
   */
  const naturezaOperacao = $('#natureza-operacao');

  /**
   * Descrição Natureza Operação
   * @type {jQuery|HTMLElement|*}
   */
  const descNatOp = $('#desc-nat-op');

  /**
   * Tipo Operação Saída
   * @type {jQuery|HTMLElement|*}
   */
  const tipoOperacaoSaida = $('#tipo-operacao-1');

  /**
   * Tipo Operação Entrada
   * @type {jQuery|HTMLElement|*}
   */
  const tipoOperacaoEntrada = $('#tipo-operacao-0');

  /**
   * Tipo Operação Entrada
   * @type {jQuery|HTMLElement|*}
   */
  const finalidadeEmissao = $('#finalidade-emissao');

  /**
   * Destino Operação
   * @type {jQuery|HTMLElement|*}
   */
  const destinoOperacao = $('#destino-operacao');

  naturezaOperacao.on('change', () => {
    const data = naturezaOperacao
      .find(':selected')
      .data();

    if (data.descNatOp.length > 60) {
      data.descNatOp = data.descNatOp.substring(0, 60);
    }

    descNatOp.val(data.descNatOp);

    if (data.tipoOperacao === 0) {
      tipoOperacaoEntrada.prop('checked', true).trigger('change');
    } else {
      tipoOperacaoSaida.prop('checked', true).trigger('change');
    }

    finalidadeEmissao
      .val(data.finalidadeEmissao)
      .trigger('change');
  });

  tipoOperacaoEntrada.on('change', () => {
    destinoOperacao.trigger('change');
  });

  tipoOperacaoSaida.on('change', () => {
    destinoOperacao.trigger('change');
  });
};

const calculosNfeProduto = {
  arredondar: (valor) => round10(valor, -2),
  nfeproduto: {},
  produtorrural: {},
  porcentagem: (valor, porcentagem) => (valor * porcentagem) / 100,
  baseCalculo: () => {
    let baseCalculo = calculosNfeProduto.nfeproduto.v_total
      + calculosNfeProduto.nfeproduto.v_outro
      + calculosNfeProduto.nfeproduto.v_frete
      + calculosNfeProduto.nfeproduto.v_seg;

    if (calculosNfeProduto.nfeproduto.tipo_desc == '0') {
      baseCalculo -= calculosNfeProduto.nfeproduto.v_desc;
    }

    return baseCalculo < 0 ? 0 : calculosNfeProduto.arredondar(baseCalculo);
  },
  baseCalculoIcms: () => {
    let baseCalculo = calculosNfeProduto.baseCalculo();

    if (calculosNfeProduto.nfeproduto.nfe_produto_ipi_imposto.adicionar_bc_icms) {
      baseCalculo += calculosNfeProduto.nfeproduto.nfe_produto_ipi_imposto.v_ipi;
    }

    baseCalculo = calculosNfeProduto.arredondar(baseCalculo);

    calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.v_bc = baseCalculo;

    return baseCalculo;
  },
  baseCalculoIcmsSt: () => {
    let baseCalculo = calculosNfeProduto.baseCalculo();

    if (calculosNfeProduto.nfeproduto.nfe_produto_ipi_imposto.adicionar_bc_icms) {
      baseCalculo += calculosNfeProduto.nfeproduto.nfe_produto_ipi_imposto.v_ipi;
    }

    baseCalculo = calculosNfeProduto.arredondar(baseCalculo);

    calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.v_bc_st = baseCalculo;

    return baseCalculo;
  },
  baseCalculoIpi: () => calculosNfeProduto.baseCalculo(),
  baseCalculoPis: () => calculosNfeProduto.baseCalculo(),
  baseCalculoCofins: () => calculosNfeProduto.baseCalculo(),
  calculaIcmsSt: (atualizaReducaoBaseCalculo, atualizaBaseCalculo) => {
    const caminhoIcms = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto;

    if (atualizaBaseCalculo) {
      caminhoIcms.v_bc_st = calculosNfeProduto.baseCalculoIcmsSt();

      switch (caminhoIcms.modalidade_bc_st) {
        case '4': // Margem Valor Agregado (%)
          caminhoIcms.v_bc_st += calculosNfeProduto.arredondar((calculosNfeProduto.porcentagem(caminhoIcms.v_bc_st, caminhoIcms.margem_adic)));
          break;
        case '5': // Pauta (valor)
          caminhoIcms.v_bc_st = caminhoIcms.preco_pauta_st;
          break;
        default:
          break;
      }
    }

    if (atualizaReducaoBaseCalculo) {
      caminhoIcms.v_bc_st -= calculosNfeProduto.arredondar(
        (calculosNfeProduto.porcentagem(caminhoIcms.v_bc_st, caminhoIcms.aliq_reducao_bc_st_calc)),
      );
    }

    let valorIcmsSt = calculosNfeProduto.porcentagem(caminhoIcms.v_bc_st, caminhoIcms.aliq_icms_st);
    valorIcmsSt -= caminhoIcms.v_icms;
    valorIcmsSt = valorIcmsSt > 0 ? valorIcmsSt : 0;
    valorIcmsSt = calculosNfeProduto.arredondar(valorIcmsSt);

    caminhoIcms.v_icms_st = valorIcmsSt;

    calculosNfeProduto.calculaIcmsStFcpRelativo(atualizaBaseCalculo);
    calculosNfeProduto.calculaIcmsFcpSt(atualizaBaseCalculo);

    return calculosNfeProduto.nfeproduto;
  },
  calculaIcmsStFcpRelativo: (atualizaBaseCalculo) => {
    const caminhoIcms = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto;

    if (atualizaBaseCalculo) {
      caminhoIcms.relativo_bc_icms_st_fcp = caminhoIcms.v_bc_st;
    }

    caminhoIcms.relativo_v_icms_st_fcp = calculosNfeProduto.arredondar(
      calculosNfeProduto.porcentagem(
        caminhoIcms.relativo_bc_icms_st_fcp,
        caminhoIcms.relativo_aliq_icms_st_fcp,
      ),
    );

    return calculosNfeProduto.nfeproduto;
  },
  calculaIcmsNormal: (atualizaReducaoBaseCalculo, atualizaBaseCalculo) => {
    const caminhoIcms = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto;

    if (atualizaBaseCalculo || atualizaReducaoBaseCalculo) {
      calculosNfeProduto.baseCalculoIcms();
    }

    if (atualizaReducaoBaseCalculo) {
      caminhoIcms.aliq_reducao_bc_calc = caminhoIcms.aliq_reducao_bc_calc || 0;
      caminhoIcms.v_bc = calculosNfeProduto.arredondar(
        caminhoIcms.v_bc
        - calculosNfeProduto.porcentagem(
          caminhoIcms.v_bc,
          caminhoIcms.aliq_reducao_bc_calc,
        ),
      );
    }

    const valorIcms = calculosNfeProduto.arredondar(
      calculosNfeProduto.porcentagem(
        calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.v_bc,
        calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.aliq_icms,
      ),
    );

    calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.v_icms = valorIcms;
    calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.v_icms_op = valorIcms;

    if (atualizaReducaoBaseCalculo || atualizaBaseCalculo) {
      // Atualiza o credito de ICMS
      calculosNfeProduto.calculaIcmsCredito();
      calculosNfeProduto.calculaIcmsInterestadual(true);
      calculosNfeProduto.calculaIcmsFcp(true);
    }

    if (!atualizaReducaoBaseCalculo && !atualizaBaseCalculo) {
      // Para calcular a diferença entre a aliquota o ICMS normal e ICMS st
      calculosNfeProduto.calculaIcmsSt(false, false);
      calculosNfeProduto.calculaIcmsInterestadual(true);
    }

    return calculosNfeProduto.nfeproduto;
  },
  calculaDiferimento: (calculaFcp) => {
    const caminhoIcms = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto;

    caminhoIcms.p_dif = caminhoIcms.p_dif || 0;
    const valorDiferido = calculosNfeProduto.arredondar(
      calculosNfeProduto.porcentagem(caminhoIcms.v_icms_op, caminhoIcms.p_dif),
    );

    caminhoIcms.v_icms_dif = valorDiferido;
    caminhoIcms.v_icms -= valorDiferido;
    if (calculaFcp && valorDiferido > 0) {
      caminhoIcms.v_icms_fcp = (caminhoIcms.v_icms_fcp - valorDiferido < 1 ? 0 : caminhoIcms.v_icms_fcp - valorDiferido);
    }

    return calculosNfeProduto.nfeproduto;
  },
  calculaIcmsFcpSt: (atualizaBaseCalculo) => {
    const caminhoIcms = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto;

    if (atualizaBaseCalculo) {
      caminhoIcms.bc_icms_st_fcp = caminhoIcms.v_bc_st;
    }

    let valorIcmsFcp;
    if (caminhoIcms.aliq_icms_st_fcp <= caminhoIcms.aliq_icms_fcp) {
      valorIcmsFcp = 0;
    } else {
      valorIcmsFcp = calculosNfeProduto.arredondar(
        calculosNfeProduto.porcentagem(
          caminhoIcms.bc_icms_st_fcp,
          (caminhoIcms.aliq_icms_st_fcp - caminhoIcms.aliq_icms_fcp),
        ),
      );
    }

    caminhoIcms.v_icms_st_fcp = valorIcmsFcp;

    return calculosNfeProduto.nfeproduto;
  },
  calculaIcmsFcp: (atualizaBaseCalculo) => {
    const caminhoIcms = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto;

    if (atualizaBaseCalculo) {
      caminhoIcms.bc_icms_fcp = caminhoIcms.v_bc;
    }

    caminhoIcms.aliq_icms_fcp = caminhoIcms.aliq_icms_fcp || 0;

    caminhoIcms.v_icms_fcp = calculosNfeProduto.arredondar(
      calculosNfeProduto.porcentagem(caminhoIcms.bc_icms_fcp, caminhoIcms.aliq_icms_fcp),
    );

    return calculosNfeProduto.nfeproduto;
  },
  calculaIcmsInterestadual: (atualizaBaseCalculo) => {
    const caminhoIcmsInterestadual = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.nfe_produto_icms_interestadual;

    if (caminhoIcmsInterestadual.adc_icms_interestadual) {
      if (atualizaBaseCalculo) {
        caminhoIcmsInterestadual.red_v_bc_icms_uf_destino = caminhoIcmsInterestadual.red_v_bc_icms_uf_destino || 0;
        const reducao = calculosNfeProduto.arredondar(
          calculosNfeProduto.porcentagem(
            calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.v_bc,
            caminhoIcmsInterestadual.red_v_bc_icms_uf_destino,
          ),
        );

        caminhoIcmsInterestadual.v_bc_icms_uf_destino = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.v_bc
          - reducao;
      }

      let valor = (calculosNfeProduto.porcentagem(caminhoIcmsInterestadual.v_bc_icms_uf_destino, caminhoIcmsInterestadual.aliq_interna_uf_dest))
        - (calculosNfeProduto.porcentagem(calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.v_bc, caminhoIcmsInterestadual.aliq_interestadual_uf_envolvidas));

      valor = calculosNfeProduto.arredondar(valor);

      let valorDestino = calculosNfeProduto.arredondar(
        calculosNfeProduto.porcentagem(valor, caminhoIcmsInterestadual.perc_partilha),
      );
      let valorRemetente = valor - valorDestino;

      if (valorDestino < 0) {
        valorDestino = 0;
      }
      if (valorRemetente < 0) {
        valorRemetente = 0;
      }

      caminhoIcmsInterestadual.v_uf_destino = valorDestino;
      caminhoIcmsInterestadual.v_uf_remetente = valorRemetente;
    }

    return calculosNfeProduto.nfeproduto;
  },
  calculaIcmsCredito: () => {
    const caminhoIcms = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto;
    caminhoIcms.aliq_calc_cred = caminhoIcms.aliq_calc_cred || 0;
    const valorCredito = calculosNfeProduto.arredondar(
      calculosNfeProduto.porcentagem(
        caminhoIcms.v_bc,
        caminhoIcms.aliq_calc_cred,
      ),
    );

    caminhoIcms.v_calc_cred = valorCredito;

    return valorCredito;
  },
  calculaIcmsStRetido: (atualizaBaseCalculo) => {
    const caminhoIcms = calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto;

    if (atualizaBaseCalculo) {
      caminhoIcms.bc_icms_st_fcp_retido = caminhoIcms.v_bc_st;
    }

    caminhoIcms.v_icms_st_fcp_retido = calculosNfeProduto.arredondar(
      calculosNfeProduto.porcentagem(caminhoIcms.bc_icms_st_fcp_retido, caminhoIcms.relativo_icms_st_fcp_retido),
    );
    caminhoIcms.v_icms_st_retido = calculosNfeProduto.arredondar(
      calculosNfeProduto.porcentagem(caminhoIcms.bc_icms_st_retido, caminhoIcms.aliq_icms_st_retido),
    );

    return calculosNfeProduto.nfeproduto;
  },
  calculaIcms: () => {
    switch (calculosNfeProduto.nfeproduto.nfe_produto_icms_imposto.icms_dado.codigo) {
      case '00':
      case '20':
        calculosNfeProduto.calculaIcmsNormal(true, true);
        break;
      case '10':
        calculosNfeProduto.calculaIcmsNormal(true, true);
        calculosNfeProduto.calculaIcmsSt(true, true);
        break;
      case '30':
        calculosNfeProduto.calculaIcmsSt(true, true);
        break;
      case '40':
      case '50':
        break;
      case '51':
        calculosNfeProduto.calculaIcmsNormal(true, true);
        calculosNfeProduto.calculaDiferimento();
        break;
      case '60':
        calculosNfeProduto.calculaIcmsStRetido(true);
        break;
      case '70':
      case '90':
      case 'Partilha 10':
      case 'Partilha 90':
        calculosNfeProduto.calculaIcmsNormal(true, true);
        calculosNfeProduto.calculaIcmsSt(true, true);
        break;
      case 'Repasse 41':
      case 'Repasse 60':
        calculosNfeProduto.calculaIcmsStRetido(true);
        break;
      case '101':
        calculosNfeProduto.calculaIcmsCredito();
        break;
      case '102':
      case '103':
      case '300':
      case '400':
        calculosNfeProduto.calculaIcmsNormal(false, true);
        calculosNfeProduto.calculaIcmsSt(false, true);
        break;
      case '201':
        calculosNfeProduto.calculaIcmsCredito();
        calculosNfeProduto.calculaIcmsSt(true, true);
        break;
      case '202':
      case '203':
        calculosNfeProduto.calculaIcmsSt(true, true);
        break;
      case '500':
        calculosNfeProduto.calculaIcmsStRetido(true, true);
        break;
      case '900':
        calculosNfeProduto.calculaIcmsNormal(false, true);
        calculosNfeProduto.calculaIcmsSt(true, true);
        calculosNfeProduto.calculaDiferimento();
        break;
      default:
        break;
    }

    return calculosNfeProduto.nfeproduto;
  },
  calculaIpi: (atualizaBaseCalculo) => {
    const caminhoIpi = calculosNfeProduto.nfeproduto.nfe_produto_ipi_imposto;

    if (atualizaBaseCalculo) {
      caminhoIpi.v_bc = calculosNfeProduto.baseCalculoIpi();
    }

    switch (caminhoIpi.tipo_bc) {
      case '1':
        // "Porcentagem"
        caminhoIpi.porc_ipi = caminhoIpi.porc_ipi || 0;
        caminhoIpi.v_ipi = calculosNfeProduto.arredondar(
          calculosNfeProduto.porcentagem(caminhoIpi.v_bc, caminhoIpi.porc_ipi),
        );
        break;
      case '2':
        // "Em Valor"
        caminhoIpi.v_ipi = calculosNfeProduto.arredondar(
          (caminhoIpi.v_unid * calculosNfeProduto.nfeproduto.quant_com),
        );
        break;
      default:
        // "Não Usar"
        caminhoIpi.v_ipi = 0;
        break;
    }

    return calculosNfeProduto.nfeproduto;
  },
  calculaPisSemTributos: (status) => {
    const caminhoPis = calculosNfeProduto.nfeproduto.nfe_produto_pis_imposto;

    if (status) {
      caminhoPis.tipo_calc = '1';
      caminhoPis.v_total = 0;
      caminhoPis.v_aliq_reais = 0;
      caminhoPis.v_aliq_porc = 0;
    } else if (caminhoPis.tipo_calc === '1') {
      caminhoPis.v_aliq_reais = 0;
    } else {
      caminhoPis.v_aliq_porc = 0;
    }
  },
  calculaCofinsSemTributos: (status) => {
    const caminhoCofins = calculosNfeProduto.nfeproduto.nfe_produto_cofins_imposto;

    if (status) {
      caminhoCofins.tipo_calc = '1';
      caminhoCofins.v_total = 0;
      caminhoCofins.v_aliq_reais = 0;
      caminhoCofins.v_aliq_porc = 0;
    } else if (caminhoCofins.tipo_calc === '1') {
      caminhoCofins.v_aliq_reais = 0;
    } else {
      caminhoCofins.v_aliq_porc = 0;
    }
  },
  calculaPis: (atualizaBaseCalculo) => {
    const caminhoPis = calculosNfeProduto.nfeproduto.nfe_produto_pis_imposto;

    if (atualizaBaseCalculo) {
      const novaBaseCalculo = calculosNfeProduto.baseCalculoPis();

      caminhoPis.v_bc = novaBaseCalculo;
      caminhoPis.v_bc_st = novaBaseCalculo;
    }

    caminhoPis.v_aliq_porc = caminhoPis.v_aliq_porc || 0;
    switch (caminhoPis.pis_dado.codigo) {
      case '01':
      case '02':
        caminhoPis.v_total = calculosNfeProduto.arredondar(
          calculosNfeProduto.porcentagem(caminhoPis.v_bc, caminhoPis.v_aliq_porc),
        );

        caminhoPis.v_bc_st = 0;
        caminhoPis.v_aliq_st_eais = 0;
        caminhoPis.v_aliq_st_porc = 0;
        caminhoPis.v_total_st = 0;

        caminhoPis.tipo_calc = '1';
        caminhoPis.v_aliq_reais = 0;
        break;
      case '03':
        caminhoPis.v_total = calculosNfeProduto.arredondar(
          (caminhoPis.v_aliq_reais * calculosNfeProduto.nfeproduto.quant_com),
        );
        caminhoPis.v_bc_st = 0;
        caminhoPis.v_aliq_st_eais = 0;
        caminhoPis.v_aliq_st_porc = 0;
        caminhoPis.v_total_st = 0;

        caminhoPis.tipo_calc = '1';
        caminhoPis.v_aliq_porc = 0;
        break;
      case '04':
      case '06':
      case '07':
      case '08':
      case '09':
        caminhoPis.v_bc_st = 0;
        caminhoPis.v_aliq_st_eais = 0;
        caminhoPis.v_aliq_st_porc = 0;
        caminhoPis.v_total_st = 0;

        calculosNfeProduto.calculaPisSemTributos(true);
        break;
      case '05':
        switch (caminhoPis.tipo_calc_st) {
          case '1':
            caminhoPis.v_total_st = calculosNfeProduto.arredondar(
              calculosNfeProduto.porcentagem(caminhoPis.v_bc_st, caminhoPis.v_aliq_st_porc),
            );
            caminhoPis.v_aliq_st_eais = 0;
            break;
          case '2':
            caminhoPis.v_total_st = calculosNfeProduto.arredondar(
              (caminhoPis.v_aliq_st_eais * calculosNfeProduto.nfeproduto.quant_com),
            );
            caminhoPis.v_aliq_st_porc = 0;
            break;
          default:
            caminhoPis.v_aliq_st_eais = 0;
            caminhoPis.v_aliq_st_porc = 0;
            caminhoPis.v_total_st = 0;
            break;
        }

        calculosNfeProduto.calculaPisSemTributos(true);
        break;
      case '49':
      case '50':
      case '51':
      case '52':
      case '53':
      case '54':
      case '55':
      case '56':
      case '60':
      case '61':
      case '62':
      case '63':
      case '64':
      case '65':
      case '66':
      case '67':
      case '70':
      case '71':
      case '72':
      case '73':
      case '74':
      case '75':
      case '98':
      case '99':
        if (caminhoPis.tipo_calc == '1') {
          caminhoPis.v_total = calculosNfeProduto.arredondar(
            calculosNfeProduto.porcentagem(caminhoPis.v_bc, caminhoPis.v_aliq_porc),
          );
        } else {
          caminhoPis.v_total = calculosNfeProduto.arredondar(
            (caminhoPis.v_aliq_reais * calculosNfeProduto.nfeproduto.quant_com),
          );
        }

        caminhoPis.v_bc_st = 0;
        caminhoPis.v_aliq_st_eais = 0;
        caminhoPis.v_aliq_st_porc = 0;
        caminhoPis.v_total_st = 0;

        calculosNfeProduto.calculaPisSemTributos(false);
        break;
      default:
        caminhoPis.v_bc_st = 0;
        caminhoPis.v_aliq_st_eais = 0;
        caminhoPis.v_aliq_st_porc = 0;
        caminhoPis.v_total_st = 0;

        calculosNfeProduto.calculaPisSemTributos(true);
        break;
    }

    return calculosNfeProduto.nfeproduto;
  },
  calculaCofins: (atualizaBaseCalculo) => {
    const caminhoCofins = calculosNfeProduto.nfeproduto.nfe_produto_cofins_imposto;

    if (atualizaBaseCalculo) {
      const novaBaseCalculo = calculosNfeProduto.baseCalculoCofins();

      caminhoCofins.v_bc = novaBaseCalculo;
      caminhoCofins.v_bc_st = novaBaseCalculo;
    }

    caminhoCofins.v_aliq_porc = caminhoCofins.v_aliq_porc || 0;
    switch (caminhoCofins.cofins_dado.codigo) {
      case '01':
      case '02':
        caminhoCofins.v_total = calculosNfeProduto.arredondar(
          calculosNfeProduto.porcentagem(caminhoCofins.v_bc, caminhoCofins.v_aliq_porc),
        );
        caminhoCofins.tipo_calc = '1';
        caminhoCofins.v_aliq_reais = 0;

        caminhoCofins.v_bc_st = 0;
        caminhoCofins.v_aliq_st_eais = 0;
        caminhoCofins.v_aliq_st_porc = 0;
        caminhoCofins.v_total_st = 0;
        break;
      case '03':
        caminhoCofins.v_total = calculosNfeProduto.arredondar(
          (caminhoCofins.v_aliq_reais * calculosNfeProduto.nfeproduto.quant_com),
        );
        caminhoCofins.tipo_calc = '2';
        caminhoCofins.v_aliq_porc = 0;

        caminhoCofins.v_bc_st = 0;
        caminhoCofins.v_aliq_st_eais = 0;
        caminhoCofins.v_aliq_st_porc = 0;
        caminhoCofins.v_total_st = 0;
        break;
      case '04':
      case '06':
      case '07':
      case '08':
      case '09':
        caminhoCofins.v_bc_st = 0;
        caminhoCofins.v_aliq_st_eais = 0;
        caminhoCofins.v_aliq_st_porc = 0;
        caminhoCofins.v_total_st = 0;

        calculosNfeProduto.calculaCofinsSemTributos(true);
        break;
      case '05':
        switch (caminhoCofins.tipo_calc_st) {
          case '1':
            caminhoCofins.v_total_st = calculosNfeProduto.arredondar(
              calculosNfeProduto.porcentagem(caminhoCofins.v_bc_st, caminhoCofins.v_aliq_st_porc),
            );
            caminhoCofins.v_aliq_st_eais = 0;
            break;
          case '2':
            caminhoCofins.v_total_st = calculosNfeProduto.arredondar(
              (caminhoCofins.v_aliq_st_eais * calculosNfeProduto.nfeproduto.quant_com),
            );
            caminhoCofins.v_aliq_st_porc = 0;
            break;
          default:
            caminhoCofins.v_aliq_st_eais = 0;
            caminhoCofins.v_aliq_st_porc = 0;
            caminhoCofins.v_total_st = 0;
            break;
        }

        calculosNfeProduto.calculaCofinsSemTributos(true);
        break;
      case '49':
      case '50':
      case '51':
      case '52':
      case '53':
      case '54':
      case '55':
      case '56':
      case '60':
      case '61':
      case '62':
      case '63':
      case '64':
      case '65':
      case '66':
      case '67':
      case '70':
      case '71':
      case '72':
      case '73':
      case '74':
      case '75':
      case '98':
      case '99':
        if (caminhoCofins.tipo_calc == '1') {
          caminhoCofins.v_total = calculosNfeProduto.arredondar(
            calculosNfeProduto.porcentagem(caminhoCofins.v_bc, caminhoCofins.v_aliq_porc),
          );
          caminhoCofins.v_aliq_reais = 0;
        } else {
          caminhoCofins.v_total = calculosNfeProduto.arredondar(
            (caminhoCofins.v_aliq_reais * calculosNfeProduto.nfeproduto.quant_com),
          );
          caminhoCofins.v_aliq_porc = 0;
        }

        caminhoCofins.v_bc_st = 0;
        caminhoCofins.v_aliq_st_eais = 0;
        caminhoCofins.v_aliq_st_porc = 0;
        caminhoCofins.v_total_st = 0;

        calculosNfeProduto.calculaCofinsSemTributos(false);
        break;
      default:
        caminhoCofins.v_bc_st = 0;
        caminhoCofins.v_aliq_st_eais = 0;
        caminhoCofins.v_aliq_st_porc = 0;
        caminhoCofins.v_total_st = 0;

        calculosNfeProduto.calculaCofinsSemTributos(true);
        break;
    }

    return calculosNfeProduto.nfeproduto;
  },
  calculaIbpt: () => {
    const caminhoIbpt = calculosNfeProduto.nfeproduto.nfe_produto_ibpt_imposto;

    let baseCalculo = calculosNfeProduto.nfeproduto.v_total;
    if (calculosNfeProduto.nfeproduto.tipo_desc == '0') {
      baseCalculo -= calculosNfeProduto.nfeproduto.v_desc;
    }

    caminhoIbpt.porc_municipal = caminhoIbpt.porc_municipal || 0;
    caminhoIbpt.porc_estadual = caminhoIbpt.porc_estadual || 0;
    caminhoIbpt.porc_federal = caminhoIbpt.porc_federal || 0;

    const valorMunicipal = calculosNfeProduto.porcentagem(baseCalculo, caminhoIbpt.porc_municipal);
    const valorEstadual = calculosNfeProduto.porcentagem(baseCalculo, caminhoIbpt.porc_estadual);
    const valorFederal = calculosNfeProduto.porcentagem(baseCalculo, caminhoIbpt.porc_federal);

    caminhoIbpt.v_total = calculosNfeProduto.arredondar(valorMunicipal + valorEstadual + valorFederal);
    caminhoIbpt.v_municipal = calculosNfeProduto.arredondar(valorMunicipal);
    caminhoIbpt.v_estatual = calculosNfeProduto.arredondar(valorEstadual);
    caminhoIbpt.v_federal = calculosNfeProduto.arredondar(valorFederal);

    return calculosNfeProduto.nfeproduto;
  },
  gerarInfoComplemataresAutomaticas: (nfeprodutos) => {
    const caminhoProdutorRural = calculosNfeProduto.produtorrural;
    let infAut = {};
    let infAutGeradas = '';

    if (caminhoProdutorRural.produtor_rural) {
      for (const key in nfeprodutos) {
        const produto = nfeprodutos[key];

        if (produto.nfe_produto_nfpe_tributos) {
          for (const tributo of produto.nfe_produto_nfpe_tributos) {
            const tributoId = tributo.nfpe_tributo_id;
            const valor = tributo.valor;

            if (infAut[tributoId]) {
              infAut[tributoId].valor += valor;
            } else {
              infAut[tributoId] = {
                observacoes_nota: tributo.tributo_observacoes_nota,
                valor: valor,
              };
            }
          }
        }
      }

      for (let key in infAut) {
        if (infAut[key].valor > 0) {
          infAutGeradas += (infAut[key].observacoes_nota).replace('[vTributo]', maskedValue(infAut[key].valor)) + '\n';
        }
      }
    }

    infAutGeradas = infAutGeradas.slice(0, -1); // código para remover o "\n" inserido no final do texto
    calculosNfeProduto.produtorrural.inf_aut = infAutGeradas;

    return calculosNfeProduto.produtorrural;
  },
  calculaNfpeTributos: () => {
    const caminhoProdutorRural = calculosNfeProduto.produtorrural;

    if (caminhoProdutorRural.produtor_rural == '1') {
      const caminhoNfpe = calculosNfeProduto.nfeproduto.nfe_produto_nfpe_tributos;
      caminhoProdutorRural.nfpe_upf_valor = caminhoProdutorRural.nfpe_upf_valor || 0;

      caminhoNfpe.map((nfe_produto_nfpe_tributo) => {
        let valorCalculado;
        let valor = nfe_produto_nfpe_tributo.tributo_valor_formatado;

        if (nfe_produto_nfpe_tributo.tributo_usar_upf) {
          valor *= caminhoProdutorRural.nfpe_upf_valor;
        }

        switch (nfe_produto_nfpe_tributo.tributo_tipo_calculo) {
          case 'quantidade':
            valorCalculado = valor * calculosNfeProduto.nfeproduto.quant_com;
            break;
          case 'valor-total':
            valorCalculado = valor * (calculosNfeProduto.nfeproduto.v_total - calculosNfeProduto.nfeproduto.v_desc);
            break;
          default:
            valorCalculado = 0;
            break;
        }

        nfe_produto_nfpe_tributo.valor = calculosNfeProduto.arredondar(valorCalculado);
      });
    }

    return calculosNfeProduto.nfeproduto;
  },
  recalcular: () => {
    calculosNfeProduto.calculaIcms();
    calculosNfeProduto.calculaIpi(true);
    calculosNfeProduto.calculaPis(true);
    calculosNfeProduto.calculaCofins(true);
    calculosNfeProduto.calculaIbpt();
    calculosNfeProduto.calculaNfpeTributos();

    return calculosNfeProduto.nfeproduto;
  },
};

window.initNFe = nfe;
window.calculosNfeProduto = calculosNfeProduto;
