import Croppie from 'croppie';

const readFile = (input) => {
  let inputRead = $(input);
  let imgCroppie = $('<img>').attr({
    class: 'fileUpload',
    src: ''
  });
  inputRead.parent().find('.croppie-container').remove();
  inputRead.parent().append(imgCroppie);

  if (input.files && input.files[0]) {
    const image = (input.files[0]['name']);
    let extension = ((/[.]/.exec(image)) ? /[^.]+$/.exec(image)[0] : undefined);
    extension = extension !== 'png' ? 'jpeg' : 'png';

    let reader = new FileReader();

    reader.onload = (e) => {
      imgCroppie.attr('src', e.target.result);
      let croppieInstance = new Croppie(imgCroppie[0], {
        boundary: {
          height: inputRead.data('boundary-height')
        },
        customClass: '',
        enableOrientation: false,
        enableResize: false,
        enableZoom: true,
        enforceBoundary: true,
        mouseWheelZoom: false,
        showZoomer: true,
        viewport: {
          width: inputRead.data('viewport-width'),
          height: inputRead.data('viewport-height'),
          type: 'square'
        }
      });
      $(imgCroppie[0])
        .parent()
        .on('update.croppie', (ev, cropData) => {
          croppieInstance
            .result({
              type: 'base64',
              size: 'original',
              format: extension,
              quality: 0.8,
              circle: false
            })
            .then((dataImg) => {
              inputRead.parent().find('.encodedImage').val(inputRead.val().split(/(\\|\/)/g).pop() + ',' + dataImg);
            });
        });
    };
    reader.readAsDataURL(input.files[0]);
  }
};

window.readFile = readFile;
