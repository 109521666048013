import $ from 'jquery';
import 'jsignature/src/jSignature';
import 'jsignature/src/plugins/jSignature.CompressorSVG';

function Signature(saveCallback, telaCheia = false, dataNative = null, validaAssinatura = true, selector = '#confirmar', event = 'click', height = null) {
  let assinatura;
  if (telaCheia) {
    //const largura = $('#section-assinatura').width();
    let altura = $('#section-assinatura').height() - 150;

    if (altura < 140) {
      altura = 140;
    }

    assinatura = $('#assinatura').jSignature({'width': '100%', 'height': altura});
  } else if (height != null && height > 0) {
    assinatura = $('#assinatura').jSignature({'width': '100%', 'height': height});
  } else {
    assinatura = $('#assinatura').jSignature();
  }
  assinatura.jSignature('addPlugin', 'CompressorSVG');

  if (dataNative) {
    assinatura.jSignature('setData', dataNative, 'native');
  }

  $('#limpar').click(() => {
    assinatura.jSignature('reset');
  });

  $(selector).on(event, () => {
    const data = assinatura.jSignature('getData', 'native');
    if (data.length <= 0 && validaAssinatura) {
      // eslint-disable-next-line no-undef
      toastr.error('Por favor, assine o documento.');
    } else {
      saveCallback(assinatura.jSignature('getData'), assinatura.jSignature('getData', 'native'));
    }
  });

  $('#desfazer').click(() => {
    const data = assinatura.jSignature('getData', 'native');

    data.pop();
    assinatura.jSignature('setData', data, 'native');
  });
}

function destroySignature() {
  $('#assinatura').jSignature('destroy');
}

window.initSignature = Signature;
window.destroySignature = destroySignature;
