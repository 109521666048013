import $ from 'jquery';
import axios from 'axios';
import { Modal } from 'bootstrap';
import { md5 } from 'js-md5';

const painel = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

function preencherCodigos(atual, proximo, arrayCodigos) {
  arrayCodigos[proximo].focus();
  const valor = arrayCodigos[atual].val();

  if (valor.length > 1) {
    for (let i = 0; i < valor.length; i += 1) {
      if (i < (5 - atual)) {
        arrayCodigos[i + proximo].val(valor[i + 1]).focus();
      } else {
        break;
      }
    }

    arrayCodigos[atual].val(valor[0]);
  }
}

class AuthScreen {
  constructor(title, message, groups, descricaoTemporaria, successCallback, failureCallback) {
    this.title = title;
    this.message = message;
    this.groups = groups;
    this.descricaoTemporaria = descricaoTemporaria;
    this.successCallback = successCallback;
    this.failureCallback = failureCallback;
    this.sucesso = false;

    this.modalId = md5(`${Date.now()}`);

    this.modalElement = this.createModalElement();
    this.modal = new Modal(this.modalElement);
    document.body.appendChild(this.modalElement);

    this.codigosBrowser = [
      $(`#${this.modalId}codigo-1`),
      $(`#${this.modalId}codigo-2`),
      $(`#${this.modalId}codigo-3`),
      $(`#${this.modalId}codigo-4`),
      $(`#${this.modalId}codigo-5`),
      $(`#${this.modalId}codigo-6`),
    ];

    this.codigosBrowser[0].on('input', () => {
      preencherCodigos(0, 1, this.codigosBrowser);
    });
    this.codigosBrowser[1].on('input', () => {
      preencherCodigos(1, 2, this.codigosBrowser);
    });
    this.codigosBrowser[2].on('input', () => {
      preencherCodigos(2, 3, this.codigosBrowser);
    });
    this.codigosBrowser[3].on('input', () => {
      preencherCodigos(3, 4, this.codigosBrowser);
    });
    this.codigosBrowser[4].on('input', () => {
      preencherCodigos(4, 5, this.codigosBrowser);
    });
  }

  createModalElement() {
    let modalElement = document.getElementById(this.modalId);
    if (!modalElement) {
      modalElement = document.createElement('div');
      modalElement.classList.add('modal');
      modalElement.id = this.modalId;
      modalElement.innerHTML = `
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">${this.title}</h4>
              <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
              <p>${this.message}</p>

              <p>Informe abaixo o código de 6 dígitos gerado pelo gerente:</p>

              <div class="form-row">
                <div class="form-group col-2">
                  <input oninput="this.value = this.value.toUpperCase()" id="${this.modalId}codigo-1" type="text" class="form-control text-center" maxlength="6">
                </div>
                <div class="form-group col-2">
                  <input oninput="this.value = this.value.toUpperCase()" id="${this.modalId}codigo-2" type="text" class="form-control text-center" maxlength="5">
                </div>
                <div class="form-group col-2">
                  <input oninput="this.value = this.value.toUpperCase()" id="${this.modalId}codigo-3" type="text" class="form-control text-center" maxlength="4">
                </div>
                <div class="form-group col-2">
                  <input oninput="this.value = this.value.toUpperCase()" id="${this.modalId}codigo-4" type="text" class="form-control text-center" maxlength="3">
                </div>
                <div class="form-group col-2">
                  <input oninput="this.value = this.value.toUpperCase()" id="${this.modalId}codigo-5" type="text" class="form-control text-center" maxlength="2">
                </div>
                <div class="form-group col-2">
                  <input oninput="this.value = this.value.toUpperCase()" id="${this.modalId}codigo-6" type="text" class="form-control text-center" maxlength="1">
                </div>

                <p class="text-danger" id="${this.modalId}erro"></p>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" id="${this.modalId}modalAuthSubmit">Enviar</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
            </div>
          </div>
        </div>
      `;
    }

    return modalElement;
  }

  show() {
    this.modal.show();

    this.codigosBrowser[0].focus();
    this.codigosBrowser[5].keyup((e) => {
      if (e.which === 13) {
        $(`#${this.modalId}modalAuthSubmit`).trigger('click');
      }
    });

    $(`#${this.modalId}`).on('hidden.bs.modal', () => {
      if (!this.sucesso) {
        this.failureCallback();
      } else {
        let codigo = '';
        for (let i = 0; i < this.codigosBrowser.length; i += 1) {
          codigo += this.codigosBrowser[i].val();
        }

        this.successCallback(codigo);
      }
    });

    $(`#${this.modalId}modalAuthSubmit`).click(() => {
      $(`#${this.modalId}modalAuthSubmit`).attr('disabled', true);
      let codigo = '';
      for (let i = 0; i < this.codigosBrowser.length; i += 1) {
        codigo += this.codigosBrowser[i].val();
      }

      painel.post('/admin/users/codecheck', {
        groups: this.groups,
        descricaoTemporaria: this.descricaoTemporaria,
        codigo,
      }).then((response) => {
        console.log(response);
        if (!response.redirected) {
          this.sucesso = true;
          this.modal.hide();
        } else {
          this.sucesso = false;
          $(`#${this.modalId}erro`).text('Você não tem permissão para validar o código. Entre em contato com nosso suporte');
        }
      }).catch((error) => {
        this.sucesso = false;
        $(`#${this.modalId}erro`).text('Código não encontrado!');
      }).finally(() => {
        $(`#${this.modalId}modalAuthSubmit`).removeAttr('disabled');
      });
    });
  }
}

window.AuthScreen = AuthScreen;
