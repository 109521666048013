import $ from 'jquery';
import {unmaskedValue, maskedValue} from "../inputmask";

function fieldLabel(atributes = {}) {
  let padrao = {
    'for': '',
    'class': 'control-label',
    'text': '',
  };

  atributes = Object.assign(padrao, atributes);

  // remover indice 'text' do objeto
  const { text } = atributes;
  delete atributes.text;

  return $('<label>')
    .attr(atributes)
    .text(text);
}

function fieldInput(atributes = {}) {
  let atributesInput = {
    'type': 'text',
    'name': '',
    'class': 'form-control',
    'value': '',
  };

  atributesInput = Object.assign(atributesInput, atributes);

  return $('<input>').attr(atributesInput);
}

const fieldMonetary = (atributes) => {
  const divCol = $('<div>').attr('class', 'form-group col-sm-12 col-md-6 col-lg-3 col-xl-3');
  const label = fieldLabel(atributes.label);

  const divInputGroup = $('<div>').attr('class', 'input-group');
  const spanInputGroupPrepend = $('<span>').attr('class', 'input-group-prepend');
  const spanInputGroupText = $('<span>').attr('class', 'input-group-text').text('R$');

  let atributesInput = {
    'data-inputmask-alias': 'currency',
    'inputmode': 'numeric',
    'decimals': 2,
    'data-inputmask': "'digits': '2'",
  };
  atributesInput = Object.assign(atributesInput, atributes.input);
  atributesInput.value = maskedValue(atributesInput.value || 0, {alias: 'currency', digits: atributesInput.decimals});
  const input = fieldInput(atributesInput);

  return divCol.append(label).append(divInputGroup.append(spanInputGroupPrepend.append(spanInputGroupText)).append(input));
};

window.fieldLabel = fieldLabel;
window.fieldInput = fieldInput;
window.fieldMonetary = fieldMonetary;
