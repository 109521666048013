const { s, co } = require("@fullcalendar/core/internal-common");

function checkbox(input, changeVal = false) {
  if (input.is(':checked')) {
    input
      .parent()
      .parent()
      .parent()
      .find('strong')
      .text(input.data('text-checked'))
      .removeClass('text-danger')
      .addClass('text-success');

    if (changeVal) {
      input.val('1');
    }
  } else {
    input
      .parent()
      .parent()
      .parent()
      .find('strong')
      .text(input.data('text-unchecked'))
      .removeClass('text-success')
      .addClass('text-danger');

    if (changeVal) {
      input.val('0');
    }
  }
}

function changeLimit(event) {
  event.preventDefault();
  const query = new URLSearchParams(window.location.search);
  const url = new URL(window.location.href);
  if (query.has('page')) {
    query.delete('page');
  }
  if (query.has('limit')) {
    query.set('limit', document.getElementById('limit').value);
  } else {
    query.append('limit', document.getElementById('limit').value);
  }
  window.location.href = `${url.protocol}//${url.host}${url.pathname}?${query.toString()}`;
}

function toggleFullScreen() {
  if (!document.fullscreenElement && !document.mozFullScreenElement
    && !document.webkitFullscreenElement && !document.msFullscreenElement) { // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}

async function checkEntitiesStatus(entities, urlCheck, crudName, genero) {
  let continuar = true;
  // termos
  let finalizado = 'finalizada';
  let action = `Esta ${crudName}`;
  if (genero && genero.toUpperCase() === 'M') {
    action = `Este ${crudName}`;
    finalizado = 'finalizado';
  }

  await entities.map(async (entity) => {
    if (continuar) {
      await $.ajax({
        url: urlCheck,
        method: 'GET',
        async: false,
        data: {
          id: entity.id,
          tipo: entity.tipo,
        },
      })
        .done((data) => {
          if (!data.status) {
            toastr.error(`Atenção! ${action} não pode ser ${finalizado} pois este ${entity.termo} está inativo.`);
          }
          continuar = data.status;
        })
        .fail(() => {
          continuar = true;
        });
    }
  });

  return continuar;
}

function formatEntityName(pessoa, pessoaFisica, pessoaJuridica) {
  const entity = pessoa.entity ? pessoa.entity : pessoa.tipo_entity;
  let tipo;
  let entityName;
  switch (entity) {
    case '1':
    case 'F':
      entityName = '[Fornecedor';
      tipo = 'F';
      break;
    case '2':
    case 'E':
      entityName = '[Funcionário';
      tipo = 'E';
      break;
    case '3':
    case 'T':
      entityName = '[Transportadora';
      tipo = 'T';
      break;
    case '4':
    case 'C':
      entityName = '[Cliente';
      tipo = 'C';
      break;
    default:
      entityName = '';
      tipo = '';
  }

  let doc = '';
  if (pessoa.tipo === pessoaFisica && (pessoa.cpf !== '' && pessoa.cpf !== null)) {
    doc = `CPF: ${pessoa.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}`;
  } else if (pessoa.tipo === pessoaJuridica && (pessoa.cnpj !== '' && pessoa.cnpj !== null)) {
    doc = `CNPJ: ${pessoa.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')}`;
  }

  if (doc.length > 0) {
    entityName += (entityName.length > 0) ? ` - ${doc}]` : `[${doc}]`;
  } else if (entityName.length > 0) {
    entityName += ']';
  }

  if (pessoa.apelido_nome_fantasia == null) {
     pessoa.apelido_nome_fantasia = '';
  }

  return {
    tipo,
    text: `${pessoa.nome_razao_social} ${entityName}`,
    subtext: `${pessoa.apelido_nome_fantasia}`,
  };
}

function removeModalAll() {
  $('div.modals .modal').each(function (item, element) {
    $(`#${element.id}`).remove();
  });
}

function removeModal(modalId) {
  // remove modal se ele existir
  const modalExistente = $(`${modalId}`);
  if (modalExistente.length > 0) {
    modalExistente.remove();
  }
}

function createModal(id, body, classTamanho = 'modal-xl', show = false) {
  // div padrão para colocar todos os modais
  const divModal = $('div.modals');
  // remove modal se ele existir
  removeModal(`#modal${id}`);
  // constroi o modal
  const divModalContent = $('<div>').addClass('modal-content p-2');
  const divModalDialog = $('<div>')
    .addClass(`modal-dialog ${classTamanho} modal-dialog-centered`)
    .attr({
      role: 'document',
    });
  const divModalNovo = $('<div>')
    .addClass('modal fade')
    .attr({
      id: `modal${id}`,
      'data-backdrop': 'static',
      'data-keyboard': 'false',
      tabindex: '-1',
      role: 'dialog',
      'aria-labelledby': `modal${id}Label`,
      'aria-hidden': 'true',
    });
  divModalDialog.append(divModalContent);
  divModalNovo.append(divModalDialog);
  divModal.append(divModalNovo);
  // coloca conteúdo no modal
  divModalContent.html(body);
  // mostra ou não o modal
  if (show) {
    divModalNovo.modal('show');
  }
}

function toggleCard(button, mostrar, id) {
  $(button).attr('onclick', `toggleCard($(this), ${!mostrar}, '${id}')`);
  if (mostrar) {
    $(`#${id}`).fadeIn();
    $(button).html('<i class="fas fa-arrow-up mr-1"></i> Ver menos');
  } else {
    $(`#${id}`).fadeOut();
    $(button).html('<i class="fas fa-arrow-down mr-1"></i> Ver mais');
  }
}

function adicionarLoad() {
  $('body').addClass('overlay');
}

function removerLoad() {
  $('body').removeClass('overlay');
}

window.toggleCard = toggleCard;
window.removerLoad = removerLoad;
window.adicionarLoad = adicionarLoad;
window.checkbox = checkbox;
window.changeLimit = changeLimit;
window.toggleFullScreen = toggleFullScreen;
window.checkEntitiesStatus = checkEntitiesStatus;
window.formatEntityName = formatEntityName;
window.createModal = createModal;
window.removeModal = removeModal;
window.removeModalAll = removeModalAll;

// Estilizando Topo Mobile
$(document).ready(() => {
  const filtro = '<div id="bodyfilter"></div>';
  $('.link-mobile-top').on('click', () => {
    if (!$(this).data('clicked')) {
      $(this).data('clicked', true);
      $('.nav-item-mobile-top').toggleClass('open-menu-mobile');
      $(filtro).appendTo('body');
      $('#bodyfilter').click(() => {
        $('.nav-item-mobile-top').removeClass('open-menu-mobile');
        $('#bodyfilter').remove();
        $('.link-mobile-top').trigger('click');
      });
    } else {
      $(this).data('clicked', false);
      $('.nav-item-mobile-top').removeClass('open-menu-mobile');
      $('#bodyfilter').remove();
    }
  });
  $('.main-header > .navbar-nav.ml-auto > .nav-item.dropdown > .nav-link').click(() => {
    $(this).data('clicked', !$(this).data('clicked'));
    $('#bodyfilter').remove();
    $('.nav-item-mobile-top').removeClass('open-menu-mobile');
  });
});

let previousKeyModal = 0;
$(document)
  .on('hidden.bs.modal', () => {
    if ($('.modal.show').length > 0) {
      $('body').addClass('modal-open');
    }
  })
  .on('keydown', (event) => {
    if ($('body').hasClass('modal-open') && previousKeyModal === 18) {
      switch (event.keyCode) {
        case 66: // Alt + B
          event.preventDefault();
          $('#buscarCnpjButton').click();
          break;
        case 67: // Alt + C
          event.preventDefault();
          $('.modal.fade.show').last().modal('hide');
          break;
        case 71: // Alt + G
          event.preventDefault();
          $('.modal.fade.show .btn-gerar').first().click();
          break;
        case 73: // Alt + I
          event.preventDefault();
          $('.modal.fade.show .btn-importar').first().click();
          break;
        case 78: // Alt + N
          event.preventDefault();
          $('.modal.fade.show .btn-nao').first().click();
          break;
        case 80: // Alt + P
          event.preventDefault();
          $('.modal.fade.show .btn-primary').first().click();
          break;
        case 82: // Alt + R
          event.preventDefault();
          $('.modal.fade.show .btn-reenviar').first().click();
          break;
        case 83: // Alt + S
          event.preventDefault();
          $('.modal.fade.show .btn-salvar').first().click();
          break;
        case 84: // Alt + T
          event.preventDefault();
          $('.modal.fade.show .btn-tentar').first().click();
          break;
        case 86: // Alt + V
          event.preventDefault();
          $('.modal.fade.show .btn-vincular-depois').first().click();
          break;
        default:
      }
    }
    previousKeyModal = event.keyCode;
  });

// Função para buscar com Enter no select2 nos filtros
const btnBuscarSelect2 = (selector, btnId = '#btnBuscar') => {

  const EnterKey = (e) => {
    if (e.which === 13 && $(e.target).hasClass('select2-search__field')) {
      $(btnId).click();
      e.preventDefault();
    }
  };

  $(selector).on('select2:select', () => {
    $(document)
      .off('keydown', EnterKey)
      .on('keydown', EnterKey);
  });
};

window.btnBuscarSelect2 = btnBuscarSelect2;
