import $ from 'jquery';

class Agenda {
  constructor(
    containerPreenchimentoAutomatico,
    containerButtonsPreenchimento,
    containerDiasSemana,
    containerHorarios,
  ) {
    this.containerPreenchimentoAutomatico = $(containerPreenchimentoAutomatico);
    this.containerButtonsPreenchimento = $(containerButtonsPreenchimento);
    this.containerDiasSemana = $(containerDiasSemana);
    this.containerHorarios = $(containerHorarios);

    this.diasSemana = [
      false,
      true,
      true,
      true,
      true,
      true,
      false,
    ];
    this.nomesDiasDaSemana = [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ];
    this.namesDiasDaSemana = [
      'domingo',
      'segunda',
      'terca',
      'quarta',
      'quinta',
      'sexta',
      'sabado',
    ];
    this.horariosPreenchimento = [];
    this.horarios = [];

    this.renderPreenchimentoAutomatico();
  }

  renderPreenchimentoAutomatico() {
    const buttonsContainer = this.containerButtonsPreenchimento;

    const btnAdicionarHorario = $('<button>').attr({
      type: 'button',
      class: 'btn btn-sm btn-primary mr-2',
    }).html('<i class="fa fa-plus"></i> Adicionar horário');
    btnAdicionarHorario.click(() => {
      this.handleClickAdicionarHorario();
    });

    const btnAplicarPreenchimento = $('<button>').attr({
      type: 'button',
      class: 'btn btn-sm btn-primary mr-2',
    }).html('<i class="fas fa-check"></i> Aplicar Preenchimento');
    btnAplicarPreenchimento.click(() => {
      this.handleClickAplicarPreenchimento();
    });

    buttonsContainer.prepend(btnAplicarPreenchimento).prepend(btnAdicionarHorario);

    this.createButtonsDiasSemana();
  }

  handleClickAdicionarHorario() {
    this.createEntradaSaida(true);
  }

  handleClickAplicarPreenchimento() {
    this.horarios = [];

    for (let i = 0; i < this.horariosPreenchimento.length; i += 1) {
      const horario = this.horariosPreenchimento[i];

      this.horarios[i] = {};
      for (let dia = 0; dia < this.diasSemana.length; dia += 1) {
        const nomeDia = this.namesDiasDaSemana[dia];
        this.horarios[i][`entrada_${nomeDia}`] = this.diasSemana[dia] ? horario.entrada : '00:00';
        this.horarios[i][`saida_${nomeDia}`] = this.diasSemana[dia] ? horario.saida : '00:00';
      }
    }

    this.renderHorarios();
  }

  createEntradaSaida(showRemoverButton = false) {
    const index = this.horariosPreenchimento.length;

    const dados = {
      entrada: '',
      saida: '',
    };
    this.horariosPreenchimento[index] = dados;

    const container = $('<div>').attr({
      class: 'col-xs-12 col-md-6',
    });

    const row = $('<div>').attr({
      class: 'row',
      style: 'margin-bottom: 10px;',
    });

    const classeCol = `input-group ${showRemoverButton ? 'col-sm-5 col-xs-12' : 'col-sm-6 col-xs-12'}`;

    const colEntrada = $('<div>').attr({
      class: classeCol,
    });
    const divInputGroupEntrada = $('<div>').addClass('input-group-prepend');
    divInputGroupEntrada.append($('<span>').addClass('input-group-text').text('Entrada'));
    colEntrada.append(divInputGroupEntrada);

    const colSaida = $('<div>').attr({
      class: classeCol,
    });
    const divInputGroupSaida = $('<div>').addClass('input-group-prepend');
    divInputGroupSaida.append($('<span>').addClass('input-group-text').text('Saída'));
    colSaida.append(divInputGroupSaida);

    const inputEntrada = $('<input>').attr({
      type: 'time',
      class: 'form-control',
      id: `entrada-${index}`,
    });
    inputEntrada.on('input', (e) => {
      dados.entrada = e.target.value;
    });
    const inputSaida = $('<input>').attr({
      type: 'time',
      class: 'form-control',
      id: `saida-${index}`,
    });
    inputSaida.on('input', (e) => {
      dados.saida = e.target.value;
    });

    colEntrada.append(inputEntrada);
    colSaida.append(inputSaida);

    row.append(colEntrada).append(colSaida);

    if (showRemoverButton) {
      const col3 = $('<div>').attr({
        class: 'col-sm-2 col-xs-12',
      });

      const btnRemover = $('<button>').attr({
        type: 'button',
        class: 'btn btn-sm btn-danger',
      }).html('<i class="fa fa-trash"></i> Excluir');
      btnRemover.click(() => {
        container.remove();
        this.horariosPreenchimento.splice(index, 1);
      });

      col3.append(btnRemover);

      row.append(col3);
    }

    container.append(row);

    this.containerPreenchimentoAutomatico.append(container);
  }

  renderBotoesDiasSemana() {
    for (let i = 0; i < this.diasSemana.length; i += 1) {
      const marcado = this.diasSemana[i];
      const botao = $(`#dia-semana-${i}`);
      const diaLabel = this.nomesDiasDaSemana[i];

      botao.attr('marcado', marcado ? 'true' : 'false');
      botao
        .empty()
        .html(`<i class="far fa-${marcado ? 'check-square' : 'square'} mr-1"></i>${diaLabel}`);
    }
  }

  renderHorarios() {
    this.containerHorarios.empty();

    for (let i = 0; i < this.horarios.length; i += 1) {
      const divHorarios = $('<div>').addClass('col-12');

      const buttonRemover = $('<button>').attr({
        type: 'button',
        class: 'btn btn-sm btn-danger',
      }).html('<i class="fa fa-trash mr-1"></i>Remover');
      buttonRemover.click(() => {
        divHorarios.remove();
      });

      const divRemover = $('<div>').addClass('row mb-2 justify-content-between align-items-center');
      divRemover.append($('<div>').text('Entrada')).append(buttonRemover);

      divHorarios.append(divRemover);

      const divLabelSaida = $('<div>').addClass('row mb-2');
      const labelSaida = $('<div>').text('Saída');
      divLabelSaida.append(labelSaida);

      const rowEntrada = $('<div>').addClass('row mb-2 grid-dias-semana');
      const rowSaida = $('<div>').addClass('row mb-2 grid-dias-semana');

      for (let dia = 0; dia < this.diasSemana.length; dia += 1) {
        const colEntrada = $('<div>').addClass('input-group mb-3');
        const colSaida = $('<div>').addClass('input-group mb-3');

        const divInputGroupEntrada = $('<div>').addClass('input-group-prepend');
        const spanInputGroupTextEntrada = $('<span>')
          .addClass('input-group-text')
          .text(this.nomesDiasDaSemana[dia].substring(0, 3));
        divInputGroupEntrada.append(spanInputGroupTextEntrada);
        const inputEntrada = $('<input>').attr({
          type: 'time',
          class: 'form-control',
          name: `horarios[${i}][entrada_${this.namesDiasDaSemana[dia]}]`,
        });
        const divInputGroupSaida = $('<div>').addClass('input-group-prepend');
        const spanInputGroupTextSaida = $('<span>')
          .addClass('input-group-text')
          .text(this.nomesDiasDaSemana[dia].substring(0, 3));
        divInputGroupSaida.append(spanInputGroupTextSaida);
        const inputSaida = $('<input>').attr({
          type: 'time',
          class: 'form-control',
          name: `horarios[${i}][saida_${this.namesDiasDaSemana[dia]}]`,
        });

        const nomeDia = this.namesDiasDaSemana[dia];
        const horarioEntrada = this.horarios[i][`entrada_${nomeDia}`];
        const horarioSaida = this.horarios[i][`saida_${nomeDia}`];

        inputEntrada.val(horarioEntrada);
        inputSaida.val(horarioSaida);

        colEntrada.append(divInputGroupEntrada).append(inputEntrada);
        colSaida.append(divInputGroupSaida).append(inputSaida);

        rowEntrada.append(colEntrada);
        rowSaida.append(colSaida);
      }

      divHorarios.append(rowEntrada);
      divHorarios.append(divLabelSaida);
      divHorarios.append(rowSaida);

      this.containerHorarios.append(divHorarios);
    }
  }

  createButtonsDiasSemana() {
    const container = $('<div>').attr({
      class: 'col-xs-12 col-md-12',
    });

    const diasSemana = $('<div>').attr({
      class: 'grid-dias-semana',
    });

    for (let i = 0; i < this.nomesDiasDaSemana.length; i += 1) {
      const diaDaSemana = this.nomesDiasDaSemana[i];
      const marcado = this.diasSemana[i];

      const botao = $('<button>').attr({
        class: 'btn btn-sm btn-primary',
        type: 'button',
        id: `dia-semana-${i}`,
        marcado: marcado ? 'true' : 'false',
      }).html(`<i class="far fa-${marcado ? 'check-square' : 'square'} mr-1"></i>${diaDaSemana}`);
      botao.click(() => {
        this.diasSemana[i] = !this.diasSemana[i];

        this.renderBotoesDiasSemana();
      });

      diasSemana.append(botao);
    }

    container.append(diasSemana);

    this.containerDiasSemana.append(container);
  }
}

window.Agenda = Agenda;
