// Modal para janela de compartilhamento
// via WhatsApp

const ModalCompartilharWhatsApp = {
  /**
   * Valida e monta o número de telefone do destinatario
   *
   * @param {integer} destinatario Numero a mensagem será enviada
   * @return {string} Número
   */
  gerarDestinatario: (destinatario) => {
    // Remove os caracteres especiais
    const destFiltrado = destinatario.replace(/\D+/g, '');
    let numeroDestinatario = '';
    const ddi = '55'; // Prefixo do país (Brasil)

    switch (destFiltrado.length) {
      // Se possui 11 ou 10 números, coloca ddi (país)
      // => 44 9 9898-9898 --OU-- => 44 9898-9898
      case 11:
      case 10:
        numeroDestinatario = ddi + destFiltrado;
        break;
      default:
        numeroDestinatario = destFiltrado;
        break;
    }

    return numeroDestinatario;
  },

  /**
   * Cria o link que será enviado via  WhatsApp
   *
   * @param {integer} destinatario Numero a mensagem será enviada
   * @param {string} texto Texto da mensagem
   */
  createLink: (destinatario, texto) => {
    const prefixo = "https:/\/\wa.me";
    const numeroDestinatario = ModalCompartilharWhatsApp.gerarDestinatario(destinatario);

    let link = prefixo;

    if (destinatario) {
      link += '/' + numeroDestinatario;
    }

    if (!texto) {
      texto = '';
    }

    link += '/?text=' + encodeURIComponent(texto);

    return link;
  },

  /**
   * Envia a mensagem via WhatsApp em uma nova guia por modal
   *
   * @requires createLink
   */
  enviar: () => {
    const destinatario = $('#modal-compartilhar-whatsapp-destinatario').val();
    const mensagem = $('#modal-compartilhar-whatsapp-mensagem').val();

    ModalCompartilharWhatsApp.disparar(destinatario, mensagem);
  },
  /**
   * Envia a mensagem via WhatsApp em uma nova guia
   * @param destinatario
   * @param mensagem
   */
  disparar(destinatario, mensagem) {
    const linkWhatsapp = ModalCompartilharWhatsApp.createLink(
      destinatario,
      mensagem,
    );
    window.open(linkWhatsapp, '_blank');
  },
  /**
   * Abre o modal de WhatsApp
   *
   * @param {integer} destinatario Número de telefone do destinatário
   * @param {string} link Link a ser compartilhado junto da mensagem em base64
   *
   * Obs 1: Strings em base64 por conta do escape no onClick do elemento.
   *
   * Obs 2: para funcionar, é necessário que o element(modal_modal_compartilhar_whatsapp)
   * esteja incluso na view.
   */
  openModal: (destinatario, link, isEncoded = true) => {
    if (isEncoded == true) {
      link = atob(link);
    }

    const modalMensagem = $('#modal-compartilhar-whatsapp-mensagem');
    const modalDestinatario = $('#modal-compartilhar-whatsapp-destinatario');

    $(modalDestinatario).val(destinatario);

    const linkSplit = link.split(' \\r\\n ');
    let novoLink = '';

    if (linkSplit.length > 1) {
      linkSplit.forEach((texto) => {
        novoLink += texto + '\r\n';
      });
    } else {
      novoLink = link;
    }

    $(modalMensagem).val(novoLink);

    const modalWhatsapp = $('#modalCompartilharWhatsapp');
    modalWhatsapp.modal('show');
  },
};

export default ModalCompartilharWhatsApp;
