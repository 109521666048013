import '../global/sentry';
import './scss/style.scss';

import 'popper.js';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import 'overlayscrollbars/js/jquery.overlayScrollbars';
import 'admin-lte';
import 'html5shiv';
import 'toastr/toastr';
import 'big.js';
// import Sortable from 'sortablejs';
import Sortable from 'sortablejs/Sortable';

import './modules/table_buttons';
import './modules/tinymce';
import './modules/tooltip';
import './modules/barra_footer';
import './modules/select2';
import './modules/inputmask';
import './modules/croppie';
import './modules/busca_cep';
import './modules/validacao_documentos';
import './modules/fullcalendar';
import './modules/agenda';
import './modules/jquery-validation';
import './modules/modal';
import './modules/impostos';
import './modules/nfe';
import './modules/fields';
import './modules/micro-templating';
import './modules/fluxo';
import './modules/dre';
import './modules/signature';
import './modules/firebase';
// import './modules/prevent-reload';
import './modules/preview-image';
import './modules/atalhos';
import './modules/clipboard';
import './modules/auth_screen';

import './funcoes';

window.Big = require('big.js');
window.Sortable = Sortable;
window.moment = require('moment');
window.zxcvbn = require('@contentpass/zxcvbn');
window.InfiniteScroll = require('infinite-scroll');

window.$ = $;

const toastr = require('toastr');

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-center',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};
window.toastr = toastr;
window.zxcvbn_pt = {
  use_a_few_words: 'Use algumas palavras, evite frases comuns',
  no_need_for_mixed_chars: 'Não há necessidades para símbolos, dígitos, ou letras maiúsculas',
  uncommon_words_are_better: 'Adicione outra palavra ou duas. Palavras incomuns são melhores.',
  straight_rows_of_keys_are_easy: 'Uma sequência de letras adjacentes no teclado é muito fácil de adivinhar.',
  short_keyboard_patterns_are_easy: 'Teclas próximas no teclado são fáceis de adivinhar',
  use_longer_keyboard_patterns: 'Use um padrão mais longo com mais caracteres',
  repeated_chars_are_easy: 'Repetições como "aaa" são fáceis de adivinhar',
  repeated_patterns_are_easy: 'Repetições como "abcabcabc" são um pouco mais difíceis de adivinhar do que "abc"',
  avoid_repeated_chars: 'Evite palavras e caracteres repetidos',
  sequences_are_easy: 'Sequências como abc ou 6543 são fáceis de adivinhar',
  avoid_sequences: 'Evite sequências',
  recent_years_are_easy: 'Anos recentes são fáceis de adivinhar',
  avoid_recent_years: 'Evite anos recentes',
  avoid_associated_years: 'Evite anos que são associados com você',
  dates_are_easy: 'Datas são muito fáceis de adivinhar',
  avoid_associated_dates_and_years: 'Evite datas e anos que são associados a você',
  top10_common_password: 'Essa senha é uma das 10 mais comuns',
  top100_common_password: 'Essa senha é uma das 100 mais comuns',
  very_common_password: 'Essa senha é muito comum',
  similar_to_common_password: 'Isso é semelhante a uma senha muito usada',
  a_word_is_easy: 'Uma única palavra é fácil de adivinhar',
  names_are_easy: 'Nomes e sobrenomes são fáceis de adivinhar',
  common_names_are_easy: 'Nomes e sobrenomes comuns são fáceis de adivinhar',
  capitalization_doesnt_help: 'A primeira letra maiúscula não ajuda muito',
  all_uppercase_doesnt_help: 'Tudo maiúsculo tão fácil de adivinhar quanto tudo minúsculo',
  reverse_doesnt_help: 'Palavras invertidas não são mais difíceis de adivinhar',
  substitution_doesnt_help: 'Substituições previsíveis como \'@\' ao invés de \'a\' não ajudam muito',
  user_dictionary: 'Essa senha não é permitida',
};

$(document).ready(() => {
  // adicionando alertas ao submeter formulario
  $('.salvar, .salvar-os, .fechar-os, .fechar-orcamento, .salvar-orcamento, .salvar-venda, .salvar-pagar, .salvar-compra, .fechar-venda, .fechar-compra, button[type=submit]').on('click', () => {
    if (!$('form').valid()) {
      window.Modal.error('Erro ao salvar', 'Existem campos incorretos nesta tela, eles foram destacados em vermelho para correção. Por favor, verifique-os e tente novamente.');
    }
  });

  // validação inputs de data
  $('input[type="date"], input[type="datetime-local"]').on('blur', (e) => {
    if ($(e.target).val() === '') {
      $(e.target).val('');
    }
  });
});
$(document).ready(function () {
  var btn = $('#btn-topo-acessible');

  $(window).scroll(function() {
    if ($(window).scrollTop() > 20) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });
  btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '20');
  });

  $(function () {
    $("#btnAvancado").click(function () {
      $('#btnAvancado').toggleClass('btn-open').animate({transition: 'all 0.3s'});;
      $('.index-table.filter .advanced-filters').toggleClass('filter-open').animate({transition: 'all 0.3s'});;

      if ($("#btnAvancado svg").hasClass('fa-search-plus')) {
        $("#btnAvancado svg").removeClass('fa-search-plus').addClass('fa-minus').animate({transition: 'all 0.3s'});
      } else {
        $("#btnAvancado svg").removeClass('fa-minus').addClass('fa-search-plus').animate({transition: 'all 0.3s'});
      }

      if ($("#btnAvancadoMobile svg").hasClass('fa-search-plus')) {
        $("#btnAvancadoMobile svg").removeClass('fa-search-plus').addClass('fa-minus').animate({transition: 'all 0.3s'});
      } else {
        $("#btnAvancadoMobile svg").removeClass('fa-minus').addClass('fa-search-plus').animate({transition: 'all 0.3s'});
      }
    });

    $(".link-menu-search").click(function () {
      $(".content-search").toggleClass("responsivo-open");
      if ($(".link-menu-search svg").hasClass('fa-search')) {
        $(".link-menu-search svg").removeClass('fa-search').addClass('fa-times').animate({transition: 'all 0.3s'});
      } else {
        $(".link-menu-search svg").removeClass('fa-times').addClass('fa-search').animate({transition: 'all 0.3s'});
      }
    });
  });

  $("#btnBuscarMobile").click(function(){
    $("#btnBuscar").trigger('click');
  });
  $("#btnAvancadoMobile").click(function(){
    $("#btnAvancado").trigger('click');
  });

  //altera title do botao
  $('button.card-tools').on('click', function() {
    if ($(this).closest('.card').hasClass('collapsing-card') || $(this).closest('.card').hasClass('collapsed-card')) {
      $(this).html(`<i class="far fa-window-minimize"></i>`).attr('title', 'Clique aqui para fechar o bloco');
    } else {
      $(this).html(`<i class="far fa-window-maximize"></i>`).attr('title', 'Clique aqui para abrir o bloco');
    }
  });

  // Ocultar ou mostrar a senha
  $('.showpassword').click(function() {

    const input = $(this).parent().parent().find('input');

    if (input.attr("type") == "password") {
        input.attr("type", "text");
        $(this).children().removeClass("fa-eye-slash").addClass("fa-eye");
    } else {
        input.attr("type", "password");
        $(this).children().removeClass("fa-eye").addClass("fa-eye-slash");
    }
});
});
