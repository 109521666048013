import 'select2';
import ptBRSelect2 from 'select2/src/js/select2/i18n/pt-BR';

// abre modal add novo ao pressionar enter
const listenSelect2 = (event) => {
  if (event.keyCode === 13) {
    $(event.target).parent().next().children()
      .children()
      .children()
      .next()
      .click();
    $('select').select2('close');
  }
};

const initSelect2 = () => {
  $('select:not(.notSelect2)').each((i, select) => {
    if (!select.classList.contains('select2-hidden-accessible')) {
      $(select).select2();
    }
  });

  $('select').on('select2:open', function () {
    $('input.select2-search__field').attr('onkeyup', 'listenSelect2(event)').trigger('change');
  });

  // on first focus (bubbles up to document), open the menu
  $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
    $(this).closest('.select2-container').siblings('select:enabled').select2('open');
  });

  // steal focus during close - only capture once and stop propogation
  $('select.select2').on('select2:closing', function (e) {
    $(e.target).data('select2').$selection.one('focus focusin', function (e) {
      e.stopPropagation();
    });
  });
};

const formatTemplateResultSelect2 = (result) => {
  // impede a formatacao do botao de add novo
  if (result.text.indexOf('<button') > -1) {
    return result.text;
  }
  let optionText = result.text;
  // formata textos que contenham caracteres < e >
  if (optionText.indexOf('<br>') === -1) {
    if (optionText.indexOf('<') > -1) {
      optionText = optionText.replaceAll('<', ' < ').replaceAll('< =', '<= ');
    }
    if (optionText.indexOf('>') > -1) {
      optionText = optionText.replaceAll('>', ' > ').replaceAll('> =', '>= ');
    }
  }

  const term = $('.select2-search__field').val() || '';
  const words = term.trim().split(" ");
  words.forEach(word => {
    optionText = optionText.replace(new RegExp(word, 'gi'), (text) => `<b>${text}</b>`);
  });

  if (result.subtext && result.subtext !== '') {
    const subtext = result.subtext.trim().split(";");
    let tagP = '';
    subtext.forEach(text => {
      if (text.trim() != '') {
        tagP += `<p class="m-0" style="font-size: 85%">${text}</p>`;
      }
    });

    return $(`<p class="m-0">${optionText}</p>${tagP}`);
  }

  return $(`<span>${optionText}</span>`);
};

window.addEventListener('DOMContentLoaded', () => {
  $.fn.select2.defaults.set('theme', 'bootstrap4');
  $.fn.select2.defaults.set('language', ptBRSelect2);
  // $.fn.select2.defaults.set('allowClear', true);
  $.fn.select2.defaults.set('placeholder', {
    id: '-1',
    text: 'Selecione',
  });
  $.fn.select2.defaults.set('templateResult', formatTemplateResultSelect2);
  initSelect2();
});

window.initSelect2 = initSelect2;
window.listenSelect2 = listenSelect2;
window.formatTemplateResultSelect2 = formatTemplateResultSelect2;
