import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://2fb0106ca3e71b7b1221d3f0a72b7311@o4507572565901312.ingest.us.sentry.io/4507572569243648",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/.*\.winsitegestao\.com\.br/,
    /^https:\/\/.*\.winsite\.com\.br/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  beforeSend(event, hint) {
    // Ignore errors from localhost
    const { request } = event;
    if (request && request.url && request.url.includes('localhost')) {
      return null;
    }
    return event;
  }
});
