import $ from 'jquery';
import {Calendar} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import axios from 'axios';

const painel = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

/**
 * @param {Date} date
 */
const formatDateTime = (date) => (
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
).slice(0, 16);

window.formatDateTime = formatDateTime;

const parseYMDHM = (s) => {
  const b = s.split(/\D+/);
  // eslint-disable-next-line no-plusplus
  return new Date(b[0], --b[1], b[2], b[3], b[4], b[5] || 0, b[6] || 0);
};
const parseYMD = (s) => {
  const b = s.split(/\D+/);
  // eslint-disable-next-line no-plusplus
  return new Date(b[0], --b[1], b[2]);
};

const enableLoading = () => {
  $('body').addClass('overlay');
};

const disableLoading = () => {
  $('body').removeClass('overlay');
};

let isSelected = false;
let lastId = null;
let id = null;

$('#titulo-evento').on('change', () => {

})

class Calendario {

  constructor() {
    this.calendarEl = document.getElementById('calendar');

    $('#titulo-evento').blur(() => {
      Calendario.campoInvalido($('#titulo-evento'), $('#titulo-evento').val().trim() !== '');
    });
    $('#inicio-evento').blur(() => {
      Calendario.campoInvalido($('#inicio-evento'), $('#inicio-evento').val() !== '');
    });
    $('#fim-evento').blur(() => {
      Calendario.campoInvalido($('#fim-evento'), $('#fim-evento').val() !== '');
    });

    function getEventosUrl() {


      return '/admin/agenda/eventos.json?id=' + window.agendaId;

    }

    this.calendar = new Calendar(this.calendarEl, {
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
      locale: ptBrLocale,
      events: getEventosUrl(),
      eventContent: (event) => {
        let sufix = "";
        let item = "";
        let borderStyle = "";
        var time = new Date(event.event.start.toISOString().slice(0, 10));
        var outraData = new Date();
        let backgroundColor = event.event.backgroundColor ? "color:" + event.event.backgroundColor + "; " : " ";
        outraData.setDate(time.getDate() + 2); // Adiciona 3 dias

        if (event.timeText.split("") != "" && event.timeText.split("") != null) {
          if (event.timeText.split("")[0] + event.timeText.split("")[1] >= 12) {
            sufix = "pm |";
          } else {
            sufix = "am |";
          }

          if (event.isStartResizable != false) {
            item = '<i class=\"fas fa-circle fa-sm \" style = \"font-size:8px;margin-bottom:1px;  vertical-align: unset;  ' + backgroundColor + ' \" ' + ' ></i>';
          }
        } else {
          borderStyle = "border-top: 0px;border-right: 0px;border-bottom: 0px;border-left: 5px solid #001e8d;border-bottom-left-radius: 3px;border-top-left-radius: 3px; ";
        }

        return {
          html: '<div class="fc-content" style=" display:flex; flex-direction: row; align-items: center; overflow-x: clip;' + borderStyle +
            ' "><h4 class="fc-time" style = "margin: 0px; font-weight: 550;line-height: 13px; padding: 4px 5px 2px 5px; font-size: 13px;">' + item + ' ' + event.timeText + sufix + ' ' + ' ' + event.event.title + " </h4> " +

            '</div>'
        }
      },
      selectable: true,

      select: async (event) => {

        this.abrirNovoModal(event);
      },
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      },
      navLinks: true, // can click day/week names to navigate views
      dayMaxEvents: true, // allow "more" link when too many events
      editable: true,
      loading: (isLoading) => {
        if (isLoading) {
          enableLoading();
        } else {
          disableLoading();
        }
      },
      eventClick: (info) => {

        new Promise((resolve, reject) => {

          $.ajax({
            url: '/admin/agenda/infoEvento',
            method: 'GET',
            data: {
              id: info.event.id,
            },
            beforeSend: function () {
              $('body').addClass('overlay');
            }
          })
            .done(async function (data) {

              const dados = formatEntityName(data, '<?= PessoasTable::$fisica?>', '<?= PessoasTable::$juridica?>');

              await createModal('Evento', data, 'modal-xl', true);

              $('#titulo-evento').blur(() => {
                Calendario.campoInvalido($('#titulo-evento'), $('#titulo-evento').val().trim() !== '');
              });
              $('#inicio-evento').blur(() => {
                Calendario.campoInvalido($('#inicio-evento'), $('#inicio-evento').val() !== '');
              });
              $('#fim-evento').blur(() => {
                Calendario.campoInvalido($('#fim-evento'), $('#fim-evento').val() !== '');
              });
              $('#título-modal-evento').text('Editar evento')
              resolve()

            })
            .fail(function (error) {
              toastr.error('Não foi possível buscar as informações do evento. Por favor, tente novamente.');

            })
            .always(function () {
              $('body').removeClass('overlay');
            })
        }).then(() => {

          $('#id-evento').val(info.event.id);
          $('#titulo-evento').val(info.event.title);
          $('#descricao-evento').val(info.event.extendedProps.description);
          $('#link-evento').val(info.event.extendedProps.link);

          if (info.event.extendedProps.agendaFuncionario != null && info.event.extendedProps.agendaFuncionario != "") {

            $('#input-agenda-evento').append(
              $('<option>').attr({
                value: info.event.extendedProps.agendaFuncionario.id
              })
                .text(info.event.extendedProps.agendaFuncionario.text)
            );

            $('#input-agenda-evento').val(info.event.extendedProps.agendaFuncionario.id).trigger('change');

            $('#id-agenda').val(info.event.extendedProps.agendaFuncionario.id);
          }

          if (info.event.extendedProps.cliente != null && info.event.extendedProps.cliente != "") {

            $('#input-cliente-evento').append(
              $('<option>').attr({
                value: info.event.extendedProps.cliente.id
              })
                .text(info.event.extendedProps.cliente.text)
            );

            $('#input-cliente-evento').val(info.event.extendedProps.cliente.id).trigger('change');

            $('#input-cliente-entidade-id').val(info.event.extendedProps.cliente.id);
            $('#input-cliente-tipo-entidade').val(info.event.extendedProps.cliente.tipo);
          }

          if (info.event.extendedProps.local) {
            $('#dropdown-localizacao').val(info.event.extendedProps.local);
          }

          $('#dia-todo-evento').prop('checked', info.event.allDay).trigger('change');

          if ($('#dia-todo-evento').is(':checked')) {
            $('#inicio-evento').attr('type', 'date');
            $('#fim-evento').attr('type', 'date');
          } else {
            $('#inicio-evento').attr('type', 'datetime-local');
            $('#fim-evento').attr('type', 'datetime-local');
          }

          const notificacoes = info.event.extendedProps.notificacoes;

          $('#notificacoes').val(JSON.stringify(notificacoes));

          if (!info.event.allDay) {
            $('#inicio-evento').val(formatDateTime(info.event.start));
            if (info.event.end !== null) {
              $('#fim-evento').val(formatDateTime(info.event.end));
            } else {
              $('#fim-evento').val(formatDateTime(info.event.start));
            }
          } else {
            $('#inicio-evento').val(info.event.start.toISOString().slice(0, 10));
            if (info.event.end !== null) {
              $('#fim-evento').val(info.event.end.toISOString().slice(0, 10));
            } else {
              $('#fim-evento').val(info.event.start.toISOString().slice(0, 10));
            }
          }

          $('#cor-evento').val(info.event.backgroundColor);

          this.habilitarExclusao();

          isSelected = true;
          lastId = info.event.id;
          $('.card-title').text('Editar evento');
          $('.button-title').show();
        })
        $('#inicio-evento').val('2023-06-01')
      },
      eventDrop: (info) => {
        this.atualizarEvento(info);
      },
      eventResize: (info) => {
        this.atualizarEvento(info);
      },
    });
    this.calendar.render();

  }

  static campoInvalido(campo, status, mensagem = 'Esse campo é requerido.') {

    if (!status) {
      campo.addClass('is-invalid');
      if (campo.parent().find('.error').length === 0) {
        campo.parent().append($('<div>').addClass('error invalid-feedback').text(mensagem));
      }
    } else {
      campo.removeClass('is-invalid');
      campo.parent().find('.error').remove();
    }
  }

  habilitarExclusao() {
    $('#delete-event').removeClass('hidden');
  }

  desabilitarExclusao() {
    $('#delete-event').addClass('hidden');
  }

  abrirNovoModal(event = null) {
    new Promise((resolve, reject) => {

      $.ajax({
        url: '/admin/agenda/infoEvento',
        method: 'GET',
        data: {
          id: id,
        },
        beforeSend: function () {
          $('body').addClass('overlay');
        }
      })
        .done(async function (data) {

          await createModal('Evento', data, 'modal-xl', true);

          $('#titulo-evento').blur(() => {
            Calendario.campoInvalido($('#titulo-evento'), $('#titulo-evento').val().trim() !== '');
          });
          $('#inicio-evento').blur(() => {
            Calendario.campoInvalido($('#inicio-evento'), $('#inicio-evento').val() !== '');
          });
          $('#fim-evento').blur(() => {
            Calendario.campoInvalido($('#fim-evento'), $('#fim-evento').val() !== '');
          })
          $('#título-modal-evento').text('Adicionar evento')
          resolve()

        })
        .fail(function (error) {
          toastr.error('Não foi possível buscar as informações do evento. Por favor, tente novamente.');

        })
        .always(function () {
          $('body').removeClass('overlay');
        })


    }).then(() => {

      $('#id-evento').val('');
      $('#titulo-evento').val('');
      $('#descricao-evento').val('');
      $('#dia-todo-evento').prop('checked', false).trigger('change');
      $('#cor-evento').val('#000000');

      this.desabilitarExclusao();

      $('.button-title').hide();
      $('.card-title').text('Criar evento');
      isSelected = false;
      if ($('#dia-todo-evento').prop('checked') === false) {
        $('#inicio-evento').val(event?formatDateTime(event.start):'');
        $('#fim-evento').val(event?formatDateTime(event.end):'');
      } else {
        $('#inicio-evento').val(event ? event.start.toISOString().slice(0, 10) : '');
        $('#fim-evento').val(event ? event.end.toISOString().slice(0, 10) : '');
      }
    })
  }

  excluirEvento() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Deseja realmente excluir esse evento?')) {
      enableLoading();
      painel
        .delete(`/admin/agenda/delete/${$('#id-evento').val()}`)
        .then(() => {
          this.calendar.getEventById($('#id-evento').val())
            .remove();
          this.limparFormulario();
        })
        .finally(() => {
          disableLoading();
          $('#modalEvento').modal('hide');
        });
    }
  };

  validarFormulario() {

    let count = 0;
    if ($('#titulo-evento').val().trim() === '') {
      $('#titulo-evento').blur()
      count += 1;
    }
    if ($('#inicio-evento').val() === '') {
      $('#inicio-evento').blur()
      count += 1;
    }
    if ($('#fim-evento').val() === '') {
      $('#fim-evento').blur()
      count += 1;
    }

    const inicio = !$('#dia-todo-evento').is(':checked') ? parseYMDHM($('#inicio-evento').val()) : parseYMD($('#inicio-evento').val());
    const fim = !$('#dia-todo-evento').is(':checked') ? parseYMDHM($('#fim-evento').val()) : parseYMD($('#fim-evento').val());

    if (inicio > fim) {
      count += 1;
      Calendario.campoInvalido($('#fim-evento'), false, 'A data final não pode ser menor que a data inicial!');
    }

    return count === 0;
  }

  limparFormulario() {
    $('#id-evento').val('');
    $('#titulo-evento').val('');
    $('#descricao-evento').val('');
    $('#dia-todo-evento').prop('checked', false).trigger('change');
    $('#inicio-evento').val('');
    $('#fim-evento').val('');
    $('#cor-evento').val('#000000');

    for (let i = 0; i < 5; i++) {
      $('#input-coeficiente-notificacao-' + i).val('');
      $('#envio-funcionario-notificacao-' + i).prop('checked', false).trigger('change');
      $('#envio-cliente-notificacao-' + i).prop('checked', false).trigger('change');
    }
  }

  limparDados() {

    $('#id-evento').val('');
    $('#titulo-evento').val('');
    $('#descricao-evento').val('');
    $('#dia-todo-evento').prop('checked', false).trigger('change');
    $('#cor-evento').val('#000000');
    $('.button-title').hide();
    $('#modalEvento').modal('hide');

  }

  lancarMenssagem(tema, mensagem) {
    $('#modalEvento .modal-content').remove('.alert');
    $('#modalEvento .modal-content').prepend('<div class="alert alert-' + tema + ' alert-dismissible mt-2 mx-0 ">\n' +
      '    <button aria-hidden="true" data-dismiss="alert" class="close" type="button">×</button>\n' +
      '    <i class="icon fas fa-exclamation-triangle"></i>\n' +
      mensagem +
      '</div>');
  }

  adicionarEvento() {
    const evento = {
      id: $('#id-evento').val(),
      titulo: $('#titulo-evento').val(),
      descricao: $('#descricao-evento').val(),
      inicio: $('#inicio-evento').val(),
      email: '',
      fim: $('#fim-evento').val(),
      entidade_id: $('#input-cliente-entidade-id').val(),
      entidade_tipo: $('#input-cliente-tipo-entidade').val(),
      agenda_id: $('#id-agenda').val(),
      link_evento: $('#link-evento').val(),
      local_evento: $('#local-evento').val(),
      dia_todo: $('#dia-todo-evento').is(':checked'),
      cor: $('#cor-evento').val(),
    };

    let notificacoes = []
    for (let i = 0; i < 5; i++) {

      if ($('#input-coeficiente-notificacao-' + i).val() && ($('#envio-funcionario-notificacao-' + i).is(':checked') == true || $('#envio-cliente-notificacao-' + i).is(':checked') == true)) {
        notificacoes.push({
          'id': $('#input-id-notificacao-' + i).val(),
          'tipo': $('#input-tipo-notificacao-' + i).val(),
          'coeficiente_envio': $('#input-coeficiente-notificacao-' + i).val(),
          'tipo_envio': $('#input-tipo-envio-notificacao-' + i).val(),
          'data_envio': $('#inicio-evento').val(),
          'enviado': false,
          'envio_funcionario': $('#envio-funcionario-notificacao-' + i).is(':checked'),
          'envio_cliente': $('#envio-cliente-notificacao-' + i).is(':checked'),
        })
      }
    }

    evento.notificacoes = notificacoes;

    if (this.validarFormulario()) {
      enableLoading();
      let promise;
      if (!evento.id) {
        promise = painel.post('/admin/agenda/add', evento);
      } else {
        promise = painel.put(`/admin/agenda/edit/${evento.id}`, evento);
      }
      promise.then(() => {
        this.calendar.refetchEvents();
        this.limparFormulario();
        $('#modalEvento').modal('hide');
      }).catch((exception) => {
        disableLoading();
        console.error(exception);

        if (exception.response && exception.response.data.message) {
          this.lancarMenssagem('danger', exception.response.data.message);
        } else {
          this.lancarMenssagem('danger', 'Não foi possível salvar o evento no momento, tente novamente.');
        }
      }).finally(() => {

      });
    }
  }

  atualizarEvento(info) {
    enableLoading();
    this.limparFormulario();
    const {event} = info;
    painel.put(`/admin/agenda/edit/${event.id}`, {
      id: event.id,
      titulo: event.title,
      descricao: event.extendedProps.descricao,
      inicio: event.start,
      fim: event.end,
      dia_todo: event.allDay,
      cor: event.backgroundColor,
    }).catch(() => {
      info.revert();
    }).finally(() => {
      disableLoading();
    });
  }
}

window.Calendario = Calendario;
