import Clipboard from 'clipboard';

function initClipboardJS(selector = '.btn-clipboard') {
  new ClipboardJS(selector);
}

function initClipboardJSByModal(idModal, selector = '.btn-clipboard') {
  new ClipboardJS(selector, {
    container: document.getElementById(idModal),
  });
}

window.ClipboardJS = Clipboard;
window.initClipboardJS = initClipboardJS;
window.initClipboardJSByModal = initClipboardJSByModal;
