import $ from 'jquery';

import ModalCompartilharWhatsApp from './compartilhar-whatsapp';

const Modal = {
  modalError: '<div class="modal fade" role="dialog" id="modal-error" style="padding-right: 15px; display: none;" '
    + 'aria-modal="true">'
    + '<div class="modal-dialog">'
    + '<div class="modal-content">'
    + '<div class="content p-2">'
    + '<div class="content-header">'
    + '<div class="callout callout-info d-flex align-items-center border-danger">'
    + '<div class="col-12">'
    + '<h4 id="modal-error-title" class="text-danger d-inline"></h4>'
    + '<button type="button" class="close" data-dismiss="modal" aria-label="Close">'
    + '<span aria-hidden="true">&times;</span>'
    + '</button>'
    + '</div>'
    + '</div>'
    + '</div>'
    + '<div class="container-fluid">'
    + '<div class="card">'
    + '<div class="card-body">'
    + '<p id="modal-error-message" class="mb-0"></p>'
    + '</div>'
    + '</div>'
    + '</div>'
    + '<div class="col-12 text-right mb-1">'
    + '<button class="btn btn-outline-danger" type="button" data-dismiss="modal">'
    + '<i class="fa fa-check mr-1"></i> Entendi'
    + '</button>'
    + '</div>'
    + '</div>'
    + '</div>'
    + '</div>'
    + '</div>',
  error: (title, message) => {
    $('body').append($(Modal.modalError));
    const modalErros = $('#modal-error');

    $('#modal-error-title').html(title);
    $('#modal-error-message').html(message);

    modalErros.modal('show');
    modalErros.on('hidden.bs.modal', () => {
      modalErros.remove();
    });
    modalErros.closest('button').focus();
  },
};

window.Modal = Modal;
window.ModalCompartilharWhatsApp = ModalCompartilharWhatsApp;

export default Modal;
