import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  onSnapshot,
  doc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import { getAuth, signInWithCustomToken, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBgoT_ieD-GIZP9Bz0fTHzGJ8o0y6S9NKE',
  authDomain: 'winsitegestao-d2d43.firebaseapp.com',
  projectId: 'winsitegestao-d2d43',
  storageBucket: 'winsitegestao-d2d43.appspot.com',
  messagingSenderId: '683675323718',
  appId: '1:683675323718:web:6761de1f75151ef905e947',
  measurementId: 'G-S10866F521',
};

let app;
let firestore;
let auth;

/**
 * Retorna o token do usuário logado
 *
 * @returns {Promise<boolean>}
 */
async function getUserToken() {
  let token = false;

  if (auth && auth.currentUser) {
    await auth.currentUser.getIdToken().then((tokenColetado) => { token = tokenColetado; });
  }

  return token;
}

/**
 * Retorna o token do usuário logado
 *
 * @returns {Promise<boolean>}
 */
async function getUserTokenResult() {
  let token = false;

  if (auth && auth.currentUser) {
    await auth.currentUser.getIdTokenResult().then((tokenColetado) => { token = tokenColetado; });
  }

  return token;
}

/**
 * Retorna o usuário logado
 *
 * @returns {Promise<*>}
 */
async function getUser() {
  if (auth) {
    return auth.currentUser;
  }

  return null;
}

/**
 * Desconecta o usuário logado
 *
 * @returns {Promise<void>}
 */
async function signOut() {
  await auth.signOut().then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
    console.log('Erro ao desconectar usuário');
    console.log(error);
  });
}

/**
 * Faz login do usuário
 *
 * @param token token gerado via PHP
 * @returns {Promise<void>}
 */
async function signIn(token) {
  const tokenAtual = await getUserToken().then((tokenUserLogado) => tokenUserLogado);

  if (token != tokenAtual) {
    await signOut();
    await signInWithCustomToken(auth, token)
        .then((userCredential) => {
          //const user = userCredential.user;
        })
        .catch((error) => {
          console.log('Erro ao logar usuário');
          console.log(error);
        });
  }

}

async function initFirebase()
{
  if (!auth) {
    app = initializeApp(firebaseConfig);
    firestore = getFirestore(app);

    auth = getAuth();

    /**
     * Define um observador no objeto Auth para identificar o usuário atual (logado)
     *
     * @type {null}
     */
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        // User is signed out
        console.log('Usuário desconectado');
      }

      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      //const uid = user.uid;
      //user.getIdToken().then((token) => { console.log(token); });
      //user.getIdTokenResult().then((idTokenResult) => { console.log(idTokenResult); });
    });
  }
}

/**
 * Cria observador para monitorar o status do pix informado
 *
 * @param token Token gerado via PHP para autenticação do user
 * @param pixId Id do pix
 * @param envNameEmpresa Env name da empresa
 * @param callback Função a ser executada quando o status do pix mudar
 * @returns {Promise<void>}
 */
async function criarPix(token, pixId, envNameEmpresa, callback) {
  await initFirebase();

  await signIn(token).then(async () => {
    const document = doc(firestore, envNameEmpresa, `pix${pixId}`);

    await setDoc(document, {
      status: false,
      created: new Date(),
    });

    const unsubscribe = onSnapshot(document, async (updatedDocument) => {
      if (updatedDocument.data()) {
        const { status } = updatedDocument.data();

        if (status) {
          callback();
          await deleteDoc(document);
          await unsubscribe();
          await signOut();
        }
      }
    });
  });
}

window.initFirebase = initFirebase;
window.getUser = getUser;
window.getUserToken = getUserToken;
window.getUserTokenResult = getUserTokenResult;
window.signOut = signOut;
window.signIn = signIn;
window.criarPix = criarPix;
