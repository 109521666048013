const uploadImage = (input) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = document.getElementById(`img-${input.id}`);
      const buttons = document.querySelectorAll(`#preview-image-${input.id} button`);
      let removeBtn = document.getElementById(`removeImagem-${input.id}`);

      if (input.dataset.type === 'add') {
        buttons.forEach((button) => {
          button.classList.toggle('d-none');
        });
      }

      if (removeBtn === null) {
        removeBtn = document.getElementById('removeImagem');
      }

      $(removeBtn).removeAttr('value');

      input.setAttribute('data-type', 'edit');
      img.src = e.target.result;
    };

    reader.readAsDataURL(input.files[0]);
  }
};

const removeImage = (id) => {
  const input = document.getElementById(id);
  const img = document.getElementById(`img-${id}`);
  const buttons = document.querySelectorAll(`#preview-image-${id} button`);

  input.value = null;
  input.setAttribute('data-type', 'add');
  img.src = img.dataset.empty;

  let remove = document.getElementById(`removeImagem-${id}`);
  if (remove === null) {
    remove = document.getElementById('removeImagem');
  }
  remove.value = true;

  buttons.forEach((button) => {
    button.classList.toggle('d-none');
  });
};

window.uploadImage = uploadImage;
window.removeImage = removeImage;
