function initBarraFooter() {
  var btn = $('#back-to-top');

  $(window).scroll(function () {
    if ($(window).scrollTop() > 100) {
      btn.addClass('show').css({'position': 'fixed', 'display': 'block'});
    } else {
      btn.removeClass('show').css({'position': 'unset', 'display': 'none'});
    }
  });
}

$(() => {
  initBarraFooter();
});

window.initBarraFooter = initBarraFooter;
