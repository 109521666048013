import $ from 'jquery';

let init = true;

const fiscalProduto = (contexto) => {
  const prefixo = (contexto !== '' ? `${contexto}-` : '');

  /**
   * NCM
   * @type {jQuery|HTMLElement|*}
   */
  const ncm = $(`#${prefixo}fiscal-produto-ncm`);

  ncm.select2({
    placeholder: {
      id: '-1',
      text: 'Digite o código ou a descrição',
    },
    language: 'pt-BR',
    minimumInputLength: 2,
    delay: 250,
    cache: true,
    allowClear: true,
    ajax: {
      dataType: 'json',
      data: (params) => ({
        q: params.term,
        estado_id: ncm.data('estadoId'),
      }),
      processResults: (data) => ({
        results: $.map(data.ncms, (value) => ({
          id: value.id,
          text: `[${value.ncm}] ${value.descricao}`,
        })),
      }),
    },
  });

  /**
   * Cest
   * @type {jQuery|HTMLElement|*}
   */
  const cest = $(`#${prefixo}fiscal-produto-cest`);

  cest.select2({
    placeholder: {
      id: '-1',
      text: 'Digite o código ou a descrição',
    },
    language: 'pt-BR',
    minimumInputLength: 2,
    cache: true,
    allowClear: true,
    ajax: {
      delay: 500,
      dataType: 'json',
      data: (params) => ({
        q: params.term,
      }),
      processResults: (data) => ({
        results: $.map(data.cests, (descricao, codigo) => ({
          id: codigo,
          text: descricao,
        })),
      }),
    },
  });

  /**
   * Ind Escala
   * @type {jQuery|HTMLElement|*}
   */
  const indEscala = $(`#${prefixo}fiscal-produto-ind-escala`);

  /**
   * Cnpj Fab
   * @type {jQuery|HTMLElement|*}
   */
  const cnpjFab = $(`#${prefixo}fiscal-produto-cnpj-fab`);

  indEscala.on('change', () => {
    if (!indEscala.is(':checked')) {
      cnpjFab
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');
    } else {
      cnpjFab
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');
    }
  });

  /**
   * Tributacao Personalizada CFOP
   * @type {jQuery|HTMLElement|*}
   */
  const cfopPersonalizado = $(`#${prefixo}fiscal-produto-cfop-personalizado`);

  /**
   * Tributacao Personalizada CFOP
   * @type {jQuery|HTMLElement|*}
   */
  const icmsPersonalizado = $(`#${prefixo}fiscal-produto-icms-personalizado`);

  /**
   * Tributacao Personalizada CFOP
   * @type {jQuery|HTMLElement|*}
   */
  const pisPersonalizado = $(`#${prefixo}fiscal-produto-pis-personalizado`);

  /**
   * Tributacao Personalizada CFOP
   * @type {jQuery|HTMLElement|*}
   */
  const cofinsPersonalizado = $(`#${prefixo}fiscal-produto-cofins-personalizado`);

  /**
   * Tributacao Personalizada CFOP
   * @type {jQuery|HTMLElement|*}
   */
  const ipiPersonalizado = $(`#${prefixo}fiscal-produto-ipi-personalizado`);

  /**
   * Container Cfop
   * @type {jQuery|HTMLElement|*}
   */
  const containerCfop = $(`#${prefixo}fiscal-produto-container-cfop`);

  /**
   * Cfop Venda
   * @type {jQuery|HTMLElement|*}
   */
  const cfopVenda = $(`#${prefixo}fiscal-produto-cfop-venda-id`);

  /**
   * Cfop PDV
   * @type {jQuery|HTMLElement|*}
   */
  const cfopPdv = $(`#${prefixo}fiscal-produto-cfop-pdv-id`);

  /**
   * Cfop Devolucao Compra
   * @type {jQuery|HTMLElement|*}
   */
  const cfopDevolucaoCompra = $(`#${prefixo}fiscal-produto-cfop-devolucao-compra-id`);

  /**
   * Cfop Compra
   * @type {jQuery|HTMLElement|*}
   */
  const cfopCompra = $(`#${prefixo}fiscal-produto-cfop-compra-id`);

  /**
   * Cfop Devolucao Venda
   * @type {jQuery|HTMLElement|*}
   */
  const cfopDevolucaoVenda = $(`#${prefixo}fiscal-produto-cfop-devolucao-venda-id`);

  /**
   * Container Icms
   * @type {jQuery|HTMLElement|*}
   */
  const containerIcms = $(`#${prefixo}fiscal-produto-container-icms`);

  /**
   * Código de Situação Tributária do ICMS
   * @type {jQuery|HTMLElement|*}
   */
  const icms = $(`#${prefixo}fiscal-produto-icms-imposto-icms-dado-id`);

  /**
   * Container Pis
   * @type {jQuery|HTMLElement|*}
   */
  const containerPis = $(`#${prefixo}fiscal-produto-container-pis`);

  /**
   * Código de Situação Tributária do PIS
   * @type {jQuery|HTMLElement|*}
   */
  const pis = $(`#${prefixo}fiscal-produto-pis-imposto-pis-dado-id`);

  /**
   * Container Cofins
   * @type {jQuery|HTMLElement|*}
   */
  const containerCofins = $(`#${prefixo}fiscal-produto-container-cofins`);

  /**
   * Código de Situação Tributária do Cofins
   * @type {jQuery|HTMLElement|*}
   */
  const cofins = $(`#${prefixo}fiscal-produto-cofins-imposto-cofins-dado-id`);

  /** *
   * Container Ipi
   * @type {jQuery|HTMLElement|*}
   */
  const containerIpi = $(`#${prefixo}fiscal-produto-container-ipi`);

  /**
   * Código de Situação Tributária do IPI
   * @type {jQuery|HTMLElement|*}
   */
  const ipi = $(`#${prefixo}fiscal-produto-ipi-imposto-ipi-dado-id`);

  cfopPersonalizado.on('change', () => {
    if (cfopPersonalizado.is(':checked')) {
      cfopVenda
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');
      cfopPdv
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');
      cfopDevolucaoCompra
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');
      cfopCompra
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');
      cfopDevolucaoVenda
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');
      containerCfop.show();
    } else {
      cfopVenda
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');
      cfopPdv
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');
      cfopDevolucaoCompra
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');
      cfopCompra
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');
      cfopDevolucaoVenda
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');
      containerCfop.hide();
    }
    if (!init) {
      cfopVenda.val(null).trigger('change.select2');
      cfopPdv.val(null).trigger('change.select2');
      cfopDevolucaoCompra.val(null).trigger('change.select2');
      cfopCompra.val(null).trigger('change.select2');
      cfopDevolucaoVenda.val(null).trigger('change.select2');
    }
  });

  icmsPersonalizado.on('change', () => {
    if (icmsPersonalizado.is(':checked')) {
      icms
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');

      containerIcms.show();
    } else {
      icms
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');

      containerIcms.hide();
    }
    if (!init) {
      icms.val(null).trigger('change.select2');
    }
  });

  pisPersonalizado.on('change', () => {
    if (pisPersonalizado.is(':checked')) {
      pis
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');

      containerPis.show();
    } else {
      pis
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');

      containerPis.hide();
    }
    if (!init) {
      pis.val(null).trigger('change.select2');
    }
  });

  cofinsPersonalizado.on('change', () => {
    if (cofinsPersonalizado.is(':checked')) {
      cofins
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');

      containerCofins.show();
    } else {
      cofins
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');

      containerCofins.hide();
    }
    if (!init) {
      cofins.val(null).trigger('change.select2');
    }
  });

  ipiPersonalizado.on('change', () => {
    if (ipiPersonalizado.is(':checked')) {
      ipi
        .prop('required', true)
        .closest('.form-group')
        .addClass('required');

      containerIpi.show();
    } else {
      ipi
        .prop('required', false)
        .closest('.form-group')
        .removeClass('required');

      containerIpi.hide();
    }
    if (!init) {
      ipi.val(null).trigger('change.select2');
    }
  });

  cfopPersonalizado.trigger('change');
  icmsPersonalizado.trigger('change');
  pisPersonalizado.trigger('change');
  cofinsPersonalizado.trigger('change');
  ipiPersonalizado.trigger('change');
  indEscala.trigger('change');

  init = false;
};

window.initFiscalProduto = fiscalProduto;
