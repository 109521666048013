import Inputmask from 'inputmask';

Inputmask.extendAliases({
  datetime: {
    inputFormat: 'dd/mm/yyyy hh:MM:ss',
    undoOnEscape: false,
  },
  date: {
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    undoOnEscape: false,
  },
  time: {
    alias: 'datetime',
    inputFormat: 'hh:MM',
    undoOnEscape: false,
  },
  currency: {
    groupSeparator: '.',
    autoGroup: true,
    digits: 2,
    radixPoint: ',',
    digitsOptional: false,
    allowMinus: false,
    removeMaskOnSubmit: true,
    unmaskAsNumber: true,
    numericInput: true,
    max: 999999999999999.999,
    undoOnEscape: false,
  },
  numeric: {
    numericInput: true,
    undoOnEscape: false,
  },
  decimal: {
    digits: 2,
    digitsOptional: false,
    undoOnEscape: false,
  },
  quantity: {
    alias: 'percentage',
    digits: 3,
    digitsOptional: true,
    min: 0.000,
    max: 9999999999.999,
    undoOnEscape: false,
  },
  integer: {
    digits: 0,
    numericInput: false,
    undoOnEscape: false,
  },
  percentage: {
    digits: 2,
    digitsOptional: true,
    numericInput: false,
    max: 100.00,
    min: 0.00,
    suffix: '',
    undoOnEscape: false,
  },
  percentageMax: {
    digits: 2,
    digitsOptional: true,
    numericInput: false,
    max: 999.00,
    min: 0.00,
    suffix: '',
    undoOnEscape: false,
  },
});

const initSelectMontaryFieldFocus = () => {
  $('input[data-inputmask-alias="currency"]').each(function () {
    $(this).on('focus', () => {
      $(this).select();
    });
  });
};

const initInputMask = () => {
  document.querySelectorAll('input, [data-inputmask]').forEach((element) => {
    if (!element.inputmask) {
      Inputmask().mask(element);
    }
  });

  initSelectMontaryFieldFocus();
};

const mask = (element) => {
  Inputmask().mask(element.get()[0]);
};

// Utilize essa opção para formatar valores
const maskedValue = (value, alias = {alias: 'currency'}) => Inputmask.format(value, alias);

const unmaskedValue = (value, alias = {alias: 'currency'}) => Inputmask.unmask(value, alias);

// Utilize essa opção para formatar valores de um input
const maskedValueField = (field) => {
  let fieldAlias;
  let fieldDigits;

  if (!(field.get()[0].inputmask)) {
    fieldAlias = field.data('inputmask-alias');
    fieldDigits = field.attr('decimals');
  } else {
    fieldAlias = field.get()[0].inputmask.opts.alias;
    fieldDigits = field.get()[0].inputmask.opts.digits;
  }

  return Inputmask.unmask(
    field.val() || '0',
    {
      alias: fieldAlias,
      digits: fieldDigits,
    },
  );
};

const unmaskedValueField = (field) => {
  let fieldAlias;
  let fieldDigits;

  if (!(field.get()[0].inputmask)) {
    fieldAlias = field.data('inputmask-alias');
    fieldDigits = field.attr('decimals');
  } else {
    fieldAlias = field.get()[0].inputmask.opts.alias;
    fieldDigits = field.get()[0].inputmask.opts.digits;
  }

  return Inputmask.unmask(
    field.val() || '0',
    {
      alias: fieldAlias,
      digits: fieldDigits,
    },
  );
};

window.addEventListener('DOMContentLoaded', () => {
  initInputMask();
  initSelectMontaryFieldFocus();
});

window.initInputMask = initInputMask;
window.maskedValue = maskedValue;
window.unmaskedValue = unmaskedValue;
window.maskedValueField = maskedValueField;
window.unmaskedValueField = unmaskedValueField;
window.mask = mask;
window.initSelectMontaryFieldFocus = initSelectMontaryFieldFocus;

export { unmaskedValue, maskedValue };
