import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods.min';
import 'jquery-validation/dist/localization/messages_pt_BR';

const slugify = require('slugify');
const moment = require('moment');

$.validator.addMethod('uniqueStringTable', (value, element, selector) => {
  let valid = true;

  $(`${selector}`).each((_key, item) => {
    if ($(item).attr('id') !== $(element).attr('id') && slugify($(item).val().toUpperCase()) === slugify(value.toUpperCase())) {
      valid = false;
    }
    return valid;
  });

  return $.validator.prototype.optional(element) || valid;
}, $.validator.format('Por favor, forneça um valor diferente.'));

/** datas */
$.validator.addMethod('datetime-br', function (value, element) {
  if (this.optional(element) && value.length === 0) {
    return true;
  }

  return moment(value, 'YYYY-MM-DD HH:mm:ss').isValid();
}, 'Por favor, forneça uma data e hora correta.');

$.validator.addMethod('date-br', (value) => value.length === 0 || moment(value, 'YYYY-MM-DD').isValid(), 'Por favor, forneça uma data correta.');

$.validator.addMethod('step', function (value, element, param) {
  if (element.type == 'datetime-local' && param == 1) {
    return true;
  }

  //https://github.com/jquery-validation/jquery-validation/blob/master/src/core.js
  let type = $(element).attr('type'),
    errorMessage = 'Step attribute on input type ' + type + ' is not supported.',
    supportedTypes = ['text', 'number', 'range'],
    re = new RegExp('\\b' + type + '\\b'),
    notSupported = type && !re.test(supportedTypes.join()),
    decimalPlaces = function (num) {
      var match = ('' + num).match(/(?:\.(\d+))?$/);
      if (!match) {
        return 0;
      }

      // Number of digits right of decimal point.
      return match[1] ? match[1].length : 0;
    },
    toInt = function (num) {
      return Math.round(num * Math.pow(10, decimals));
    },
    valid = true,
    decimals;

  // Works only for text, number and range input types
  // TODO find a way to support input types date, datetime, datetime-local, month, time and week
  if (notSupported) {
    throw new Error(errorMessage);
  }

  decimals = decimalPlaces(param);

  // Value can't have too many decimals
  if (decimalPlaces(value) > decimals || toInt(value) % toInt(param) !== 0) {
    valid = false;
  }

  return this.optional(element) || valid;
});

$.validator.addMethod('lessThan', function(value, element, params) {
    const dataInicio = new Date(value);
    const dataFim = new Date($(params).val());

    if (!/Invalid|NaN/.test(dataInicio)) {
      return dataInicio <= dataFim;
    }

    return isNaN(value) && isNaN($(params).val())
      || (Number(value) <= Number($(params).val()));
  },'Este campo deve ser menor.');

$.validator.addMethod('differenceDate', function(value, element, params) {
  const dataInicio = moment(value, 'YYYY-MM-DD');
  const dataFim = moment($(params.campo).val(), 'YYYY-MM-DD');

  if (!/Invalid|NaN/.test(dataInicio) && !/Invalid|NaN/.test(dataFim)) {

    let diferenca = dataInicio.diff(dataFim, 'days');
    diferenca = (diferenca < 0 ? (diferenca * -1) : diferenca);

    return diferenca <= params.limit;
  }

  return isNaN(value) && isNaN($(params.campo).val())
    || (Number(value) <= Number($(params.campo).val()));
},'Atenção! O intervalo das datas ultrapassa o limite.');

$.validator.methods.min = (value, element, param) => {
  if (element.inputmask) {
    return element.inputmask.unmaskedvalue() >= param;
  }
  return value >= param;
};

$.validator.methods.max = (value, element, param) => {
  if (element.inputmask) {
    return element.inputmask.unmaskedvalue() <= param;
  }
  return value <= param;
};

$(() => {
  $.validator.setDefaults({
    ignore: '.ignore',
    errorElement: 'div',
    errorPlacement: (error, element) => {
      // ADD the `invalid-feedback` class to the error element
      error.addClass('invalid-feedback');

      switch (element.prop('type')) {
        case 'checkbox':
          error.insertAfter(element.next('label'));
          break;
        case 'select-one':
          error.insertAfter(element.next('.select2'));
          break;
        case 'textarea':
          error.insertAfter(element.next('.tox-tinymce'));
          break;
        case 'file':
          if (element.next('.preview-image').length > 0) {
            error.insertAfter(element.next('.preview-image'));
          } else {
            error.insertAfter(element);
          }
          break;
        default:
          if (element.closest('.input-group').length > 0) {
            error.insertAfter(element.closest('.input-group'));
          } else {
            error.insertAfter(element);
          }
          break;
      }
    },
    highlight: (element) => {
      $(element).addClass('is-invalid').removeClass('is-valid');
    },
    unhighlight: (element) => {
      $(element).addClass('is-valid').removeClass('is-invalid');
    },
  });
});
